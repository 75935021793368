import React from "react";
import AutomativeApplication from "../components/automative/AutomativeApplication";
import AutomativeBattery from "../components/automative/AutomativeBattery";
import AutomativeModel from "../components/automative/AutomativeModel";
import ContactFooter from "../components/common/ContactFooter";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import Banner from "../components/home/Banner";

const ERickshawProducts = () => {
  return (
    <>
      <Navbar />
      <Banner
        img="/assets/battery/automotive.jpg"
        pageTitle="Enjoy The Endless Fun"
        title="To run long races, you need
        Long-lasting batteries
        "
      />
      <AutomativeBattery />
      <AutomativeApplication />
      <AutomativeModel />
      <ContactFooter />
      <Footer />
    </>
  );
};

export default ERickshawProducts;
