import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { useInView, motion } from "framer-motion";

const AboutUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { threshold: 0.5 });

  const data = [
    {
      id: 1,
      img: "assets/icons/icon4.png",
      title: "OUR MISSION",
      para: "To increase the positive competition in the market by bringing in excellence and exceeding expectations.      ",
    },
    {
      id: 2,
      img: "assets/icons/icon5.png",
      title: "OUR VISION",
      para: "To become a reputable brand in the power industry known for quality batteries across the globe.      ",
    },
    {
      id: 3,
      img: "assets/icons/icon6.png",
      title: "OUR VALUES",
      para: "To be the trusted power partners to our customers, investors, and stakeholders by delivering high-quality ATS batteries.",
    },
  ];

  return (
    <div className="bg-[#F1F1F1]">
      <div className="container-ats">
        <div className="grid lg:grid-cols-10 grid-cols-1 items-center justify-items-center lg:px-0 px-5 gap-10 py-20" >
          <div className="lg:col-span-4 space-y-4">
            <h2 className="flex flex-col gap-[0.1rem]">
              <span className="text-3xl font-semibold  tracking-wider text-primary ">
                ABOUT ATS BATTERIES
              </span>
              <h2
                className="h-[2.5px] w-24 bg-primary"
                ref={ref}
                style={{
                  transform: isInView ? "none" : "translateX(200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
              ></h2>
              <br />
              <span className="text-2xl  text-black font-semibold leading-snug">
                We are Varcas Industries Pvt. Ltd.
              </span>
              <br />
              <span className="text-base  text-black text-justify leading-relaxed">
                ATS Batteries is a technology-driven brand for solar tubular
                batteries by Varcas Industries Pvt. Ltd. ATS
                batteries follow a particular design and precise manufacturing
                according to the latest technology. The batteries can handle the
                sustained operations of daily charge-discharge at high ambient
                temperatures with fast recharging and power-packed support. The
                batteries require low maintenance and have a durable design.
              </span>
            </h2>
            <NavLink to="/about">
              <button className="flex gap-3  justify-items-center group bg-primary mt-3 rounded-full px-6 py-2 cursor-pointer">
                <BsArrowRight
                  size={24}
                  className="text-white group-hover:translate-x-2 duration-150"
                />
                <p className="text-white ">Know More</p>
              </button>
            </NavLink>
          </div>
          <div className="lg:col-span-3 ">
            <div className="relative">
              <motion.div className="  h-24 w-24 p-3   bg-primary shadow-md absolute -left-10  bottom-0 "
                ref={ref}
                initial={{ y: isInView ? 70 : 0, opacity: isInView ? 0 : 0 }}
                animate={{ y: isInView ? 0 : 70, opacity: isInView ? 1 : 0 }}
                transition={{ duration: 1 }}
              >
                <p className="text-2xl text-white font-bold text-center">10+</p>
                <p className="text-white text-[10px] text-center">
                  Years of Experience
                </p>
              </motion.div>
              <img src="/assets/images/aboutImage.jpg" alt="" className="h-96 object-cover" />
            </div>
          </div>
          <div className="lg:col-span-3">
            {data.map((item) => (
              <>
                <div className="flex  items-center justify-center gap-4 py-4">
                  <div className="bg-primary rounded-full p-3">
                    <img src={item.img} alt=" image" />
                  </div>
                  <div>
                    <p className="text-black text-xl font-semibold tracking-wider">
                      {item.title}
                    </p>
                    <p className="text-black text-sm py-2">{item.para}</p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
