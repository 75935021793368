import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import Heading from "../common/Heading";
import { TbWorldUpload } from "react-icons/tb";
import { BsArrowRight, BsPatchCheckFill } from "react-icons/bs";
import { motion, useInView } from "framer-motion";
import {
  MdOutlineSupportAgent,
  MdDomainVerification,
  MdOutlineQuickreply,
  MdSettingsBackupRestore,
} from "react-icons/md";
import {
  TbTruckDelivery,
  TbPackgeExport,
  TbTemperatureOff,
} from "react-icons/tb";
import { GiFactory, GiBrainStem } from "react-icons/gi";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { VscMultipleWindows } from "react-icons/vsc";

const OurBenefits = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <>
      <div className="container-ats space-y-12 pt-20">
        <Heading
          title="OUR BENEFITS"
          description="With long-lasting power backups and a durable body, our batteries have several benefits to ensure round the clock electricity supply.           "
        />

        <section>
          <motion.div className="lg:grid lg:grid-cols-6 grid-cols-1"
            ref={ref}
            initial={{ y: isInView ? 70 : 0, opacity: isInView ? 0 : 0 }}
            animate={{ y: isInView ? 0 : 70, opacity: isInView ? 1 : 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="relative group col-span-2 row-span-2 bg-secondary lg:w-fit">
              <div className="z-10 absolute text-white bottom-0 px-6 space-y-2 py-4">
                {/* <h2 className="text-semibold text-xl drop-shadow-2xl">
                  Lorem Ipsum is simply dummy text
                </h2> */}
                <p className="text-sm pb-2 drop-shadow-md tracking-wider">
                  Enjoy as many benefits as possible from a simple battery with
                  an ATS battery. Reach us out to find the best version
                  for you, and keep running with energy forever.
                </p>
                <NavLink to="/contact">
                  <button className="flex gap-3 items-center justify-items-center group outline outline-primary rounded-full px-6 py-1 cursor-pointer">
                    <BsArrowRight
                      size={24}
                      className="text-white group-hover:translate-x-2 duration-150"
                    />
                    <p className="text-white text-sm font-semibold">
                      Contact Us
                    </p>
                  </button>
                </NavLink>
              </div>
              <img
                src="/assets/images/our-benifit.jpg"
                className="relative lg:w-96 w-full lg:h-80 h-full backdrop-blur-sm grayscale hover:grayscale-0"
              />
            </div>
            <div className="group col-span-2 bg-secondary lg:py-0 py-6 hover:bg-black flex justify-center items-center gap-2 duration-500">
              <MdDomainVerification className="w-16 h-16 group-hover:text-white text-gray-700 group-hover:scale-105 duration-300" />
              <h2 className="group-hover:text-white font-semibold uppercase">
                Lowest Maintenance
                <br />
                Requirement
              </h2>
            </div>
            <div className="group bg-third lg:py-0 py-4 flex flex-col justify-center items-center hover:bg-primary duration-500 space-y-2">
              <BsPatchCheckFill className="w-16 h-16 group-hover:text-white text-gray-700 group-hover:scale-105 duration-300" />
              <h2 className="group-hover:text-white font-semibold text-center uppercase">
                Best Quality
              </h2>
            </div>
            <div className="group  bg-[#D9D9D9] lg:py-0 py-2 hover:bg-black flex flex-col justify-center items-center gap-2 duration-500">
              <MdOutlineQuickreply className="w-16 h-16 group-hover:text-white text-gray-700 group-hover:scale-105 duration-300" />
              <h2 className="group-hover:text-white font-semibold uppercase">
                Quick Recharge
              </h2>
            </div>
            <div className="group  bg-[#D9D9D9] lg:py-0 py-2 hover:bg-black flex flex-col justify-center items-center gap-2 duration-500">
              <MdSettingsBackupRestore className="w-16 h-16 group-hover:text-white text-gray-700 group-hover:scale-105 duration-300" />
              <h2 className="group-hover:text-white font-semibold uppercase">
                Rigid Power <br /> Backups
              </h2>
            </div>
            <div className="group  bg-third lg:py-0 py-1 hover:bg-primary flex flex-col justify-center items-center gap-2 duration-500">
              <TbWorldUpload className="w-16 h-16 group-hover:text-white text-gray-700 group-hover:scale-105 duration-300" />
              <h2 className="group-hover:text-white font-semibold">
                WORLDWIDE <br />
                SUPPORT
              </h2>
            </div>
            <div className="group  bg-[#D9D9D9] lg:py-0 py-4 flex flex-col justify-center items-center hover:bg-black duration-500">
              <VscMultipleWindows className="w-16 h-16 group-hover:text-white text-gray-700 group-hover:scale-105 duration-300" />
              <h2 className="group-hover:text-white font-semibold text-center uppercase">
                Multi-operational
              </h2>
            </div>

            <div className="group lg:py-0 py-4 bg-third flex flex-col justify-center items-center hover:bg-primary duration-500">
              <TbTruckDelivery className="w-16 h-16 group-hover:text-white text-gray-700 group-hover:scale-105 duration-300" />
              <h2 className="group-hover:text-white font-semibold text-center uppercase">
                Long Service Life
              </h2>
            </div>
            <div className="group  col-span-2 bg-gray-100 flex justify-center items-center gap-2 py-6 hover:bg-black">
              <GiBrainStem className="w-16 h-16 group-hover:text-white group-hover:scale-105 duration-300" />
              <h2 className="group-hover:text-white uppercase">
                Discharges At High <br /> Ambient Temperature
              </h2>
            </div>
            <div className="group bg-third flex flex-col justify-center items-center hover:bg-primary duration-500 lg:py-6 py-4">
              <GiFactory className="w-16 h-16 group-hover:text-white text-gray-700 group-hover:scale-105 duration-300" />
              <h2 className="group-hover:text-white font-semibold text-center uppercase">
                Power Packed
                <br /> Support
              </h2>
            </div>
            <div className="group  bg-[#D9D9D9] hover:bg-black flex flex-col justify-center items-center gap-2 duration-500 lg:py-6 py-4">
              <TbTemperatureOff className="w-16 h-16 group-hover:text-white text-gray-700 group-hover:scale-105 duration-300" />
              <h2 className="group-hover:text-white font-semibold uppercase">
                Temperature <br /> Resistant
              </h2>
            </div>
            <div className="group bg-third flex flex-col justify-center items-center hover:bg-primary duration-500 lg:py-6 py-4">
              <AiFillSafetyCertificate className="w-16 h-16 group-hover:text-white text-gray-700 group-hover:scale-105 duration-300" />
              <h2 className="group-hover:text-white font-semibold text-center uppercase">
              Vigorous <br/>Body
              </h2>
            </div>
            <div className="group  bg-[#D9D9D9] hover:bg-black flex flex-col justify-center items-center gap-2 duration-500 lg:py-6 py-4">
              <MdOutlineSupportAgent className="w-16 h-16 group-hover:text-white text-gray-700 group-hover:scale-105 duration-300" />
              <h2 className="group-hover:text-white font-semibold text-center uppercase">
                Friendly <br />
                Communication Support
              </h2>
            </div>
          </motion.div>
        </section>
      </div>
    </>
  );
};

export default OurBenefits;
