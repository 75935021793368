import React, { useRef } from "react";
import Heading from "../common/Heading";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { ImCross } from "react-icons/im";
import { motion, useInView } from "framer-motion";

const OurGallery = () => {
  let [isOpen, setIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const card = [
    

    {
      id: 1,
      icon: "../assets/images/gallery1.jpg",
      stretch: "v-stretch",
    },
    {
      id: 2,
      icon: "../assets/images/gallery2.jpg",
      stretch: "h-stretch",
    },
    {
      id: 3,
      icon: "../assets/images/gallery3.jpg",
    },
    {
      id: 4,
      icon: "../assets/images/gallery4.jpg",
    },
    {
      id: 5,
      icon: "../assets/images/gallery5.jpg",
      stretch: "v-stretch",
    },
    {
      id: 6,
      icon: "../assets/images/gallery6.jpg",
      stretch: "big-stretch",
    },
    {
      id: 7,
      icon: "../assets/images/gallery7.jpg",
    },
    {
      id: 8,
      icon: "../assets/images/gallery8.jpg",
    },
    {
      id: 9,
      icon: "../assets/images/gallery9.jpg",
    },
    {
      id: 10,
      icon: "../assets/images/gallery10.jpg",
    },
  ];

  return (
    <div className="  pt-28 pb-12">
      <div className="container-ats">
        <Heading
          title="Our Gallery"
          description="Pictures sometimes speak better than words, and our team has always been working hard to get the best in the market. Hard work and innovation together build up an enlightened future."
        />

        <div className=" pt-10">
          <motion.div className="gallery pt-10"
            ref={ref}
            initial={{ y: isInView ? 70 : 0, opacity: isInView ? 0 : 0 }}
            animate={{ y: isInView ? 0 : 70, opacity: isInView ? 1 : 0 }}
            transition={{ duration: 0.8 }}
          >
            {card.map((item) => (
              <>
                <div className={`${item.stretch} group overflow-hidden`}>
                  <img
                    src={item.icon}
                    alt=""
                    className="group-hover:scale-105 group-hover:grayscale-0 grayscale duration-500"
                    onClick={() => {
                      setModalImage(item.icon);
                      setIsOpen(true);
                    }}
                  />
                </div>
              </>
            ))}
          </motion.div>
        </div>
        {/* <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              setModalImage("");
              setIsOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-70" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-4xl transform mt-20 h-[550px]   rounded  bg-white  text-left align-middle shadow-xl transition-all">
                    <div className="py-4 flex justify-end items-end">
                      <button
                        type="button"
                        className="inline-flex justify-end  rounded-md border border-transparent bg-white px-2 py-1 text-sm font-medium text-primary focus:outline-none "
                        onClick={() => {
                          setModalImage("");
                          setIsOpen(false);
                        }}
                      >
                        <ImCross className="w-4 h-4" />
                      </button>
                    </div>

                    <div>
                      <img src={modalImage} alt="" className="w-full" />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition> */}
        {isOpen && (
          <motion.div
            key="box"
            className=" z-50 bottom-0 top-10 fixed flex justify-center items-center md:w-[90%] bg-black/30 backdrop-blur-sm"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: isOpen ? 1 : 0, scale: isOpen ? 1 : 0.5 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.5 } }}
          >
            <div className="relative shadow-md shadow-slate-400  rounded-md mx-auto border-slate-400">
              <div
                className="rounded-full bg-primary text-secondary cursor-pointer inline-block p-3 absolute right-4 -top-4 hover:scale-110 duration-500 transition hover:text-primary hover:bg-secondary"
                onClick={(e) => {
                  setIsOpen(false)
                  setModalImage("")
                }
                }
              >
                <ImCross className="" />
              </div>
              <div className=" flex justify-center items-center">

                <img src={modalImage} alt="" className="w-[500px]" />

              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default OurGallery;
