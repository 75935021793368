import React from "react";
import Heading from "../common/Heading";
import worldvd from "../../img/world.mp4";

const OurPresence = () => {
  return (
    <div className="pt-20 ">
      <div className="container-ats">
        <Heading
          title="Our Global Presence & Expanding"
          description="We are proudly available across several countries on the map. Our batteries are lighting up India, Lebanon, Turkey, UAE, Nigeria, Yemen, Jordan, Iraq & Iran.        "
        />
      </div>

      <div>
        {/* <img src="/assets/images/map 12.gif" alt="image" className="w-screen pt-7 " /> */}
        <video className="videoTag object-cover" autoPlay loop muted>
          <source src={worldvd} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default OurPresence;
