import React from "react";
import Heading from "../common/Heading";

const ModelTublar = () => {
  const data = [
    {
      id: 1,
      model: "VIPL 7000",
      capacity: "70 Ah",
      gross: "45~46KGs",
      l: "505",
      w: "190",
      h: "410",
    },
    {
      id: 2,
      model: "VIPL 10000",
      capacity: "100 Ah",
      gross: "51~52KGs ",
      l: "505",
      w: "190",
      h: "410",
    },
    {
      id: 3,
      model: "VIPL 15000",
      capacity: "150 Ah",
      gross: "57~58KGs",
      l: "505",
      w: "190",
      h: "410",
    },
    {
      id: 4,
      model: "VIPL 20000",
      capacity: "200 Ah",
      gross: "64~65KGs",
      l: "505",
      w: "190",
      h: "410",
    },
    {
      id: 5,
      model: "VIPL 22000",
      capacity: "220 Ah",
      gross: "67~68KGs ",
      l: "505",
      w: "190",
      h: "410",
    },
    {
      id: 6,
      model: "VIPL 24000",
      capacity: "240 Ah",
      gross: "70~71KGs",
      l: "505",
      w: "190",
      h: "410",
    },
    {
      id: 7,
      model: "VIPL 28000",
      capacity: "280 Ah",
      gross: "74~75KGs ",
      l: "505",
      w: "190",
      h: "410",
    },
    {
      id: 8,
      model: "VIPL 30000",
      capacity: "300 Ah",
      gross: "79~80KGs ",
      l: "505",
      w: "197",
      h: "410",
    },
    {
      id: 9,
      model: "VIPL 35000",
      capacity: "350 Ah",
      gross: "84~85KGs ",
      l: "505",
      w: "197",
      h: "410",
    },
  ];
  return (
    // space-y-8 py-8

    <div className="space-y-8 py-8">
      <div className="container-ats">
        <Heading
          title="OUR MODELS @C20"
          description="
                    We have quite a few models of powerful tubular batteries to accompany all types of requirements without any delay in the power supply.
                    "
        />
      </div>

      {/* container-ats */}
      {/* grid grid-cols-1 lg:grid-cols-5 */}

      <div className="container-ats overflow-auto">
        <table className="table-auto w-full ">
          <thead>
            <tr>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                S.No.
              </th>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Model
              </th>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Capacity Ah @C20
              </th>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Gross Weight (3 +/-)
              </th>

              <th
                colSpan="3"
                className="border-b-2 border-white bg-black text-white p-4 rounded"
              >
                Dimension
              </th>
            </tr>
            <tr>
              <th className=" border-r-2 border-white bg-black text-white p-4 rounded">
                L
              </th>
              <th className="border-r-2 border-white bg-black text-white p-4 rounded">
                W
              </th>
              <th className="border-r-2 border-white bg-black text-white p-4 rounded">
                H
              </th>
            </tr>
          </thead>
          <tbody className="">
            {data.map((e, obj) => (
              <tr className="">
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.id}
                </td>
                <td className="lg:p-2 border-r-2 border-white bg-secondary text-center">
                  {e.model}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.capacity}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.gross}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.l}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.w}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.h}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ModelTublar;
