import React from "react";
import Navbar from "./common/Navbar";
import Footer from "./common/Footer";
const TermsConditions = () => {
  return (
    <>
      <Navbar />{" "}
      <div
        className=" w-full lg:h-screen h-[50vh]  bg-no-repeat   bg-center bg-cover rounded-sm "
        style={{ backgroundImage: `url(${`/assets/images/aboutbg.png`})` }}
      >
        <div className="absolute lg:space-y-6 lg:left-16 left-6 lg:top-64 top-44">
          <div className="lg:w-[100%] w-full">
            <div className="flex items-center gap-5">
              <div className="h-10 w-[0.15rem] bg-primary"></div>
              <p className="text-sm  text-primary drop-shadow-2xl font-semibold tracking-widest">
                GET IN TOUCH WITH US
              </p>
            </div>
            <h1 className="space-y-2 fontfamily textshadow  text-xl py-3 w-full lg:text-5xl text-white  font-semibold textshadow capitalize">
              <p>Terms And Conditions</p>
            </h1>
          </div>
        </div>
      </div>
      <div className="lg:px-20 px-5 py-5">
        <h4 className="text-4xl py-3 font-semibold text-primary">
          Terms and Conditions:
        </h4>
        <h6 className="py-2 font-medium text-lg">
          NONE OF OUR TERMS AND CONDITIONS AFFECT YOUR STATUTORY RIGHTS AS A
          CONSUMER.
        </h6>

        <div>
          <div>
            <h3 className="text-lg py-2 text-primary font-semibold">
              1. In General
            </h3>
            <p className="py-2 tracking-wide text-justify">
              Access to and use of this Website and the products and services
              available through this Website (collectively, the 'Services') are
              subject to the following terms, conditions, and notices (the
              'Terms of Service). By using the Services, you agree to all of the
              Terms of Service, as may be updated by us from time to time. You
              should check this page regularly to take notice of any changes we
              may have made to the Terms of Service.
            </p>
            <p className="py-3 tracking-wide text-justify">
              Access to this Website is permitted temporarily, and we reserve
              the right to withdraw or amend the Services without notice. We
              will not be liable if, for any reason, this Website is unavailable
              at any time or for any period. From time to time, we may restrict
              access to some parts of this entire Website.
            </p>
          </div>

          <div>
            <h3 className="text-lg py-2 text-primary font-semibold">
              2. Prohibitions
            </h3>

            <p className="font-medium ">
              You must not misuse this Website. You will not:
            </p>

            <ul className="lg:px-10 px-5">
              <li className="list-disc text-justify py-2 leading-loose">
                Commit or encourage a criminal offense.
              </li>
              <li className="list-disc text-justify py-2 leading-loose">
                Transmit or distribute a virus, Trojan, worm, logic bomb, or any
                other material which is malicious, technologically harmful, in
                breach of confidence, or in any way offensive or obscene.
              </li>
              <li className="list-disc text-justify py-1 leading-loose">
                Hack into any aspect of the Service.
              </li>
              <li className="list-disc text-justify py-1 leading-loose">
                Corrupt data.
              </li>
              <li className="list-disc text-justify py-1 leading-loose">
                Cause annoyance to other users.
              </li>
              <li className="list-disc text-justify py-1 leading-loose">
                Infringe upon the rights of any other person's proprietary
                rights.
              </li>
              <li className="list-disc text-justify py-1 leading-loose">
                Send unsolicited advertising or promotional material, commonly
                called 'spam.'
              </li>
              <li className="list-disc text-justify py-1 leading-loose">
                Attempt to affect the performance or functionality of any
                computer facilities accessed through this Website.
              </li>
            </ul>

            <p className="py-3 tracking-wide text-justify">
              Breaching this provision would constitute a criminal offense under
              the Computer Misuse Act 1990. ATS Batteries will report any such
              breach to the relevant law enforcement authorities and disclose
              your identity to them.
            </p>

            <p className="py-3 tracking-wide text-justify">
              We will not be liable for any loss or damage caused by a
              distributed denial-of-service attack, viruses, or other
              technologically harmful material that may infect your computer
              equipment, computer programs, data, or other proprietary material
              due to your use of this Website or to your downloading of any
              material posted on it, or on any website linked to it.
            </p>
          </div>

          <div>
            <h3 className="text-lg py-2 text-primary font-semibold">
              3. Intellectual Properties, Software, and Content
            </h3>

            <p className="py-2 tracking-wide text-justify">
              The intellectual property rights in all software and content made
              available to you on or through this Website remains the property
              of ATS Batteries. Or its licensors and are protected by copyright laws
              and treaties worldwide.
            </p>

            <p className="py-2 tracking-wide text-justify">
              All such rights are reserved by ATS Batteries.in and its licensors.
              You may store, print, and display the content supplied solely for
              personal use. You are not permitted to publish, manipulate,
              distribute or otherwise reproduce, in any format, any of the
              content or copies of the content supplied to you or which appears
              on this Website, nor may you use any such content in connection
              with any business or commercial enterprise.
            </p>
          </div>

          <div>
            <h3 className="text-lg py-2 text-primary font-semibold">
              4. Linking to this Website
            </h3>

            <p className="py-2 tracking-wide text-justify">
              You may link to our home page, provided you do so in a way that is
              fair and legal and does not damage our reputation or take
              advantage of it. Still, you must not establish a link in such a
              way as to suggest any form of association, approval or endorsement
              on our part where none exists. You must not establish a link from
              any website that you do not own. This Website must not be framed
              on any other site, nor may you create a link to any part of this
              Website other than the home page. We reserve the right to withdraw
              linking permission without notice.
            </p>
          </div>

          <div>
            <h3 className="text-lg py-2 text-primary font-semibold">
              5. Variation
            </h3>

            <p className="py-2 tracking-wide text-justify">
              ATS Batteries shall have the right in its absolute discretion at any
              time and without notice to amend, remove or vary the Services and
              any page of this Website.
            </p>
          </div>

          <div>
            <h3 className="text-lg py-2 text-primary font-semibold">
              6. Invalidities
            </h3>

            <p className="py-2 tracking-wide text-justify">
              Suppose any part of the Terms of Service is unenforceable
              (including any provision in which we exclude our liability to
              you). In that case, the enforceability of any other part of the
              Terms of Service will not be affected by all other clauses
              remaining in full force and effect. So far as possible, where any
              clause/sub-clause or part of a clause/sub-clause can be severed to
              render the remaining part valid, the clause shall be interpreted
              accordingly. Alternatively, you agree that the clause shall be
              rectified and interpreted in a way that closely resembles the
              original meaning of the clause /sub-clause as permitted by law.
            </p>
          </div>

          <div>
            <h3 className="text-lg py-2 text-primary font-semibold">
              7. Complaints
            </h3>

            <p className="py-2 tracking-wide text-justify">
              We operate a complaints handling procedure which we will use to
              try to resolve disputes when they arise, and please let us know if
              you have any complaints or comments.
            </p>
          </div>

          <div>
            <h3 className="text-lg py-2 text-primary font-semibold">
              8. Governing Law and Jurisdiction
            </h3>

            <p className="py-2 tracking-wide text-justify pb-5">
              These terms and conditions are to be construed following the laws
              of India. If any dispute or claim is associated with these terms
              and conditions, that dispute or claim shall be subject to the
              exclusive jurisdiction of the Indian courts.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
