import React from "react";
import Heading from "../common/Heading";

const ModelInverter = () => {
  const data = [
    {
      id: 1,
      model: "N100MF",
      capacity: "90 Ah",
      gross: "26.5",
      l: "412",
      w: "175",
      h: "250",
    },
    {
      id: 2,
      model: "N100MF",
      capacity: "100 Ah",
      gross: "28.5",
      l: "412",
      w: "175",
      h: "250",
    },
    {
      id: 3,
      model: "N120N",
      capacity: "130 Ah",
      gross: "37.00",
      l: "512",
      w: "180",
      h: "260",
    },
    {
      id: 4,
      model: "N150N",
      capacity: "150 Ah",
      gross: "42.00",
      l: "512",
      w: "222",
      h: "260",
    },
    {
      id: 5,
      model: "N100J",
      capacity: "40 Ah",
      gross: "22.00",
      l: "405",
      w: "175",
      h: "270",
    },
    {
      id: 6,
      model: "N100J",
      capacity: "75 Ah",
      gross: "27.00",
      l: "405",
      w: "175",
      h: "270",
    },
    {
      id: 7,
      model: "N100J",
      capacity: "100 Ah",
      gross: "32.00",
      l: "405",
      w: "175",
      h: "270",
    },
    {
      id: 8,
      model: "N150J",
      capacity: "135 Ah",
      gross: "50.00",
      l: "500",
      w: "222",
      h: "290",
    },
    {
      id: 9,
      model: "N200J",
      capacity: "160 Ah",
      gross: "54.00",
      l: "515",
      w: "275",
      h: "285",
    },
    {
      id: 10,
      model: "N200J",
      capacity: "200 Ah",
      gross: "59.00",
      l: "515",
      w: "275",
      h: "285",
    },
    {
      id: 11,
      model: "N200J",
      capacity: "220 Ah",
      gross: "62.00",
      l: "515",
      w: "275",
      h: "285",
    },
  ];
  return (
    // space-y-8 py-8

    <div className="space-y-8 py-8">
      <div className="container-ats">
        <Heading
          title="OUR MODELS @C20"
          description="To fulfil the power requirements and justify all the power equipment. There are some models of ATS Batteries  Inverter Battery.
                    "
        />
      </div>

      {/* container-ats */}
      {/* grid grid-cols-1 lg:grid-cols-5 */}

      <div className="container-ats overflow-auto">
        <table className="table-auto w-full ">
          <thead>
            <tr>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                S.No.
              </th>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Model
              </th>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Capacity Ah @C20
              </th>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Gross Weight
              </th>

              <th
                colSpan="3"
                className="border-b-2 border-white bg-black text-white p-4 rounded"
              >
                Dimension
              </th>
            </tr>
            <tr>
              <th className=" border-r-2 border-white bg-black text-white p-4 rounded">
                L
              </th>
              <th className="border-r-2 border-white bg-black text-white p-4 rounded">
                W
              </th>
              <th className="border-r-2 border-white bg-black text-white p-4 rounded">
                H
              </th>
            </tr>
          </thead>
          <tbody className="">
            {data.map((e, obj) => (
              <tr className="">
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.id}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.model}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.capacity}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.gross}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.l}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.w}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.h}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ModelInverter;
