import React from "react";
import FaqOne from "../common/FaqOne";
import HeadingOnly from "../common/HeadingOnly";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const Faq = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const textVariants = {
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 2,
      },
    },
  };

  const spanVariants = {
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 2,
      },
    },
  };

  return (
    <>
      <div className="bg-[#F1F1F1] py-12 lg:px-0 ">
        <div className="container-ats px-5 lg:px-0 grid lg:grid-cols-5 grid-cols-1">
          <div className="col-span-2">
            <motion.div
              ref={ref}
              animate={controls}
              variants={spanVariants}
              initial={{ x: -30, opacity: 0 }}
              transition={{ duration: 4000 }}
            >
              {" "}
              <div className=" space-y-8">
                <HeadingOnly title="FREQUENTLY ASKED QUESTIONS" />
                <div>
                  <FaqOne
                    title="What are ATS Tubular Batteries?"
                    des="ATS Tubular Batteries are powerful, durable batteries manufactured by Varcas Industries Pvt. Ltd., designed to endure deep discharges and high-temperature fluctuations, making them ideal for inverters and other high-demand applications. "
                  />
                  <FaqOne
                    title="What is the warranty period for ATS Tubular Batteries?"
                    des="ATS Tubular Batteries come with a maximum warranty of 5 years."
                  />
                  <FaqOne
                    title="Can ATS Tubular Batteries operate in extreme weather conditions?"
                    des="Yes, ATS Tubular Batteries are built to run under any weather circumstances, making them suitable for various environments."
                  />

                  <FaqOne
                    title="What are the key benefits of using ATS Tubular Batteries?
                "
                    desarray={[
                      "Longer Power Backup",
                      "Space-efficient Design",
                      "Negligible Maintenance",
                      "Longer Service Life",
                      "High conductive material for efficient power delivery",
                    ]}
                  />

                  <FaqOne
                    title="What applications are ATS Tubular Batteries suitable for?
                "
                    des="They are suitable for inverters, power plants, and fire & security departments due to their robust design and reliable performance."
                  />
                </div>
              </div>
            </motion.div>
          </div>

          <div className="col-span-3">
            <motion.div
              ref={ref}
              animate={controls}
              variants={textVariants}
              initial={{ x: 50, opacity: 0 }}
              transition={{ duration: 4000 }}
            >
              {" "}
              <div className=" lg:flex hidden">
                <div className="p-4 relative">
                  <div className="lg:flex justify-center items-center px-24 pt-28 pb-20">
                    <div className="bg-[#959595] lg:w-96 h-72 animate-bounce-medium rounded-md"></div>
                  </div>
                  <img
                    src="/assets/images/faqOne.png"
                    className="absolute w-96 h-96 top-0 left-10"
                  />
                  <img
                    src="/assets/images/faqTwo.png"
                    className="absolute w-72 bottom-0 right-0"
                  />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      {/* <div class="accordion" id="accordionExample">
  <div class="accordion-item bg-white border border-gray-200">
    <h2 class="accordion-header mb-0" id="headingOne">
      <button class="
        accordion-button
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
        aria-controls="collapseOne">
        Accordion Item #1
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
      data-bs-parent="#accordionExample">
      <div class="accordion-body py-4 px-5">
        <strong>This is the first item's accordion body.</strong> It is shown by default,
        until the collapse plugin adds the appropriate classes that we use to style each
        element. These classes control the overall appearance, as well as the showing and
        hiding via CSS transitions. You can modify any of this with custom CSS or overriding
        our default variables. It's also worth noting that just about any HTML can go within
        the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item bg-white border border-gray-200">
    <h2 class="accordion-header mb-0" id="headingTwo">
      <button class="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
        aria-controls="collapseTwo">
        Accordion Item #2
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
      data-bs-parent="#accordionExample">
      <div class="accordion-body py-4 px-5">
        <strong>This is the second item's accordion body.</strong> It is hidden by default,
        until the collapse plugin adds the appropriate classes that we use to style each
        element. These classes control the overall appearance, as well as the showing and
        hiding via CSS transitions. You can modify any of this with custom CSS or overriding
        our default variables. It's also worth noting that just about any HTML can go within
        the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item bg-white border border-gray-200">
    <h2 class="accordion-header mb-0" id="headingThree">
      <button class="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
        aria-controls="collapseThree">
        Accordion Item #3
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
      data-bs-parent="#accordionExample">
      <div class="accordion-body py-4 px-5">
        <strong>This is the third item's accordion body.</strong> It is hidden by default,
        until the collapse plugin adds the appropriate classes that we use to style each
        element. These classes control the overall appearance, as well as the showing and
        hiding via CSS transitions. You can modify any of this with custom CSS or overriding
        our default variables. It's also worth noting that just about any HTML can go within
        the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
</div> */}
    </>
  );
};

export default Faq;
