import { useInView } from "framer-motion";
import React, { useRef } from "react";
import HeadingOnly from "../common/HeadingOnly";
import { GiEcology } from "react-icons/gi";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { MdWork } from "react-icons/md";
import { RiUserSettingsFill } from "react-icons/ri";
import { VscTerminalPowershell } from "react-icons/vsc";

const ERickshawBattery = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const data = [
    {
      id: 1,
      title: "100% Eco-Friendly",
      img: <GiEcology size={24} />,
    },
    {
      id: 2,
      title: "Safe To Use",
      img: <AiFillSafetyCertificate size={24} />,
    },
    {
      id: 3,
      title: "Low Maintenance",
      img: <RiUserSettingsFill size={24} />,
    },
    {
      id: 4,
      title: "Less Operational Expense",
      img: <MdWork size={24} />,
    },
    {
      id: 5,
      title: "Longer Power Reserves",
      image: "/assets/icons/pro1.png",
    },
  ];
  return (
    <div className="container-ats pt-12 md:pb-20">
      <div className="md:px-0 px-6">
        <HeadingOnly title="E-RICKSHAW BATTERY" />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-7">
        <div className="col-span-4  space-y-4 pt-8 lg:px-0 px-5">
          {/* <HeadingOnly title="E-RICKSHAW BATTERY" /> */}
          <p className="text-justify">
            The E-Rickshaw Battery is the new market trend and the new friend of
            rickshaw pullers. It comes with several comforters to the life of
            drivers and passengers, and ATS Batteries E-Rickshaw Batteries can be a
            handy advantage over several others. The higher power reserve and
            smooth supply with the tendency of fast charging is a platter served
            directly for everyone.
          </p>
          <div className="lg:p-4 space-y-4">
            {data.map((e, obj) => (
              <div
                className="flex items-center gap-4"
                ref={ref}
                style={{
                  transform: isInView ? "none" : "translateX(200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
              >
                {e.img ? (
                  <div className="p-3  bg-primary rounded-full text-white">
                    {e.img}
                  </div>
                ) : (
                  <div className="p-3  bg-primary rounded-full text-white">
                    <img src={e.image} alt="/" className="w-6 h-6" />{" "}
                  </div>
                )}

                <h2 className="font-semibold">{e.title}</h2>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="col-span-3 relative">
          <div className="lg:w-96 right-0 top-10 lg:h-[40%] border-2 lg:border-primary rounded absolute  -z-10"></div>
          <img
            src="/assets/battery/e-rickshaw.png"
            className="lg:w-full  bottom-10  pt-1 lg:flex hidden  absolute  right-5  "
          />
          <div className="lg:w-56 left-6  lg:h-36 border-2 lg:border-primary rounded absolute bottom-40 -z-10"></div>
        </div> */}
        <div className="col-span-3 relative flex items-center md:h-auto h-80 md:my-0 my-12 md:mx-0 mx-6">
          <img
            src="/assets/images/e-riskshawPage.png"
            className="w-[90%] mx-auto md:pt-16"
          />
          <div className="w-52 h-52 rounded bg-primary absolute md:top-20 top-0 right-0 -z-10 animate-bounce-medium"></div>
          <div className="md:w-96 w-72 h-40 rounded bg-black bottom-0 absolute -z-10 animate-bounce-medium"></div>
        </div>
      </div>
    </div>
  );
};

export default ERickshawBattery;
