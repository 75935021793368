import { useInView } from "framer-motion";
import React, { useRef } from "react";
import HeadingOnly from "../common/HeadingOnly";
import { CgRowFirst } from "react-icons/cg";
import { TbRecharging } from "react-icons/tb";
import { FaGasPump } from "react-icons/fa";
import { GiBatteryPack } from "react-icons/gi";
import { TiBatteryHigh } from "react-icons/ti";

const Infoinverter = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const data = [
    {
      id: 1,
      title: "Fast Recharge ",
      img: <TbRecharging size={24} />,
    },
    {
      id: 2,
      title: "Negligible Gasing",
      image: "/assets/icons/pro3.png",
    },
    {
      id: 3,
      title: "Endure Frequent Cuts",
      img: <TiBatteryHigh size={24} />,
    },
    {
      id: 4,
      title: "High Battery Capacity",
      img: <GiBatteryPack size={24} />,
    },
    {
      id: 5,
      title: "Better Performance  ",
      img: <CgRowFirst size={24} />,
    },
  ];
  return (
    <div className="container-ats pt-12 md:pb-20">
      <div className="md:px-0 px-6">
        <HeadingOnly title="INVERTER BATTERIES" />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-7">
        <div className="col-span-4  space-y-4 pt-8 lg:px-0 px-5">
          {/* <HeadingOnly title="INVERTER BATTERIES" /> */}
          <p className="text-justify">
            If you are looking for a battery that can endure long power cuts and
            can recharge in a short period, then Inverter batteries can be the
            answer. They deliver high performance suitable for all sized
            appliances and can handle the irregular current supply from the
            primary source. Inverter batteries have both tubular and flat plates
            for efficient supply.
          </p>
          <div className="lg:p-4 space-y-4">
            {data.map((e, obj) => (
              <div
                className="flex items-center gap-4"
                ref={ref}
                style={{
                  transform: isInView ? "none" : "translateX(200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
              >
                {e.img ? (
                  <div className="p-3  bg-primary rounded-full text-white">
                    {e.img}
                  </div>
                ) : (
                  <div className="p-3  bg-primary rounded-full text-white">
                    <img src={e.image} alt="/" className="w-6 h-6" />{" "}
                  </div>
                )}
                <h2 className="font-semibold">{e.title}</h2>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="col-span-3 relative">
          <div className="lg:w-96 right-0 top-10 lg:h-[40%] border-2 lg:border-primary rounded absolute  -z-10"></div>
          <img
            src="/assets/battery/Inverter.png"
            className="lg:w-full  bottom-48  pt-1 lg:flex hidden  absolute  right-5  "
          />
          <div className="lg:w-56 left-6  lg:h-36 border-2 lg:border-primary rounded absolute bottom-32 -z-10"></div>
        </div> */}
        <div className="col-span-3 relative flex items-center md:h-auto h-80 md:my-0 my-12 md:mx-0 mx-6">
          <img
            src="/assets/images/inverterBatteryOne.webp"
            className="w-[90%] mx-auto md:pt-16"
          />
          <div className="w-52 h-52 rounded bg-primary absolute md:top-20 top-0 right-0 -z-10 animate-bounce-medium"></div>
          <div className="md:w-96 w-72 h-40 rounded bg-black bottom-0 absolute -z-10 animate-bounce-medium"></div>
        </div>
      </div>
    </div>
  );
};

export default Infoinverter;
