import React from "react";
import ContactFooter from "../components/common/ContactFooter";
import { NavLink } from "react-router-dom";
import Footer from "../components/common/Footer";
import { BsArrowLeft } from "react-icons/bs";
import Navbar from "../components/common/Navbar";
import { BsArrowRight } from "react-icons/bs";
import Banner from "../components/home/Banner";

const BlogDetail = () => {
  return (
    <div>
      <Navbar />

      <Banner
        img="/assets/battery/banner-blog.png"
        pageTitle="GET IN TOUCH WITH US"
        title="Learn And Grow"
        des="Grab The Power Of Knowledge, With The Power Of ATS Solar"
      />

      <div className="lg:px-32  px-5">
        <div className=" pt-10 relative">
          <NavLink to="/blog">
            <div className="flex gap-3 py-2">
              <BsArrowLeft className="pt-1 w-6 h-6" />
              <p className="font-semibold ">Back</p>
            </div>
          </NavLink>
          <img
            src="assets/battery/img-1.png"
            className="w-full  h-96 bg-black/80 bg-blend-overlay"
          />

          <div className="absolute lg:bottom-16 bottom-10 lg:px-12 px-5 bg-black py-6 bg-opacity-50 rounded">
            <div className="flex gap-5">
              <img src="assets/images/star.svg" />

              <p className="text-white">Top Story</p>
              <p className="text-white">|</p>
              <p className="text-white">4 min read</p>
            </div>

            <h4 className="text-white pt-3 lg:text-3xl font-bold   w-full">
              Tips and Tricks to find the best inverter battery for your house
            </h4>

            <div className="flex gap-4">
              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/eye.svg" />
                <p className="text-white text-sm font-semibold">123k Views</p>
                <p className="text-white">|</p>
              </div>

              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/heart-icon.svg" />
                <p className="text-white text-sm font-semibold">10k Likes</p>
              </div>
            </div>
          </div>
        </div>
        <h3 className="font-semibold pt-4">
          By : Vanshika Khanna | 7th july 2022
        </h3>
        <p className="text-justify py-5 leading-loose">
          If you are planning to buy an inverter battery for your household,
          then there are several things you should consider. It is very
          important to understand what the right inverter is for your home. The
          power requirements at your home can be different from those at your
          neighbor’s home. Therefore, one should never just install the same
          home inverter as their neighbors. Before making a final purchase, do a
          little homework and understand the basic requirements of your home.
        </p>

        <h4 className="text-2xl font-medium">
          Step 1 - First, understand your power requirements.
        </h4>
        <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
        <p className="text-justify py-5 leading-loose">
          Every household has different power requirements depending upon the
          family members, power consumption, etc. It will secure you a perfect,
          long-term deal. In simple words, list out the number of electrical
          appliances and their types, like a fan, tube lights, television, CFL,
          etc. What do you want to run at the time of a power failure? The power
          requirement is the addition of the power consumed by various
          electrical equipment.
          <br />
          Suppose you plan to connect 2 tube lights (60 Watts), 1 fan (70
          Watts), and 2 CFLs (40 Watts). In this scenario, your total power
          requirement will be: 60 + 70 + 40 = 170 Watts.
        </p>

        <h4 className="text-2xl font-medium">
          Step 2: Check the VA Ratio of the Inverter
        </h4>
        <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
        <p className="text-justify py-5 leading-loose">
          Now, the next step is to buy an inverter with the proper Volt Ampere
          (VA) rating. VA implies the voltage and current supplied by the
          inverter to the appliances. Find out how much VA would suffice for our
          electricity requirements? Use a simple formula to figure the VA i.e.
          the ratio of total power requirements by the appliances to the power
          factor of the inverter. <br /> VA stands for Power Requirement/Power
          Factor. A power factor is nothing but the ratio of power required by
          equipment to the power supplied by the inverter. The average power
          factor of inverters is 0.7 or 0.8. Approx. VA = 170 / 0.7VA = 242.85
          <br />
          Hence, in our situation, we should be looking for an inverter of
          around 250 VA.
        </p>

        <h4 className="text-2xl font-medium">
          Step 3: Purchase an Appropriate Inverter Battery
        </h4>
        <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
        <p className="text-justify py-5 leading-loose">
          A proper battery is the backbone of an inverter. It wields the
          performance and life of the inverter. Now, we arrive at the pivotal
          question – "How long will the inverter provide backup for the
          appliances?" This question is answered by the battery capacity, which
          determines the backup time expressed in Ampere Hours (Ah). So, how to
          decide on the battery capacity? Let’s say you need a backup for 6
          hours.
        </p>
      </div>

      <div className="lg:px-32 px-5 pb-20 pt-5">
        <div className="lg:grid grid-cols-2 gap-10">
          <div>
            <img
              src="assets/battery/blog-set1.jpg"
              className="h-80 w-full rounded"
            />
          </div>
          <div className="lg:pt-0 pt-8">
            <img
              src="assets/battery/blog-set2.png"
              className="h-80 w-full rounded"
            />
          </div>
        </div>
      </div>

      <ContactFooter />
      <Footer />
    </div>
  );
};

export default BlogDetail;
