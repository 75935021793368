import React from "react";
import Heading from "../common/Heading";
import { FaCarBattery } from "react-icons/fa";
import { GiGreenhouse, GiBlockHouse } from "react-icons/gi";

const ApplicationsTublar = () => {
  const data = [
    {
      id: 1,
      img: (
        <FaCarBattery className="w-12 h-12 text-primary group-hover:text-white" />
      ),
      title: "In Inverters",
      des: "Power Tubular Batteries can run with your inverters at any place to deliver long power backups in a short recharge time. We built them compatible with all the harsh weather conditions to suit your location.",
    },
    {
      id: 2,
      img: (
        <GiGreenhouse className="w-12 h-12 text-primary group-hover:text-white" />
      ),
      title: "In Power Plants",
      des: "With their longer service life and low maintenance requirements, they can easily fit the needs to provide high-voltage power supplies. They can quickly fulfil ample power supplies' needs with their long-lasting battery.      ",
    },
    {
      id: 3,
      img: (
        <GiBlockHouse className="w-12 h-12 text-primary group-hover:text-white" />
      ),
      title: "Fire & Security Department",
      des: "Due to their long-lasting power backup and rigid design, they are compatible to work in several conditions. It makes them suitable for the fire and security departments as they run smoothly with low nurturing.      ",
    },
  ];
  return (
    <div className="px-4 bg-secondary lg:space-y-16 space-y-8 py-12">
      <div className="container-ats">
        <Heading
          title="OUR APPLICATION"
          description="Our Powerful Tubular Batteries are a complete package of power. The muscular body and rigid design can be used for several applications."
        />
      </div>
      <div className="grid lg:grid-cols-3 container-ats  grid-cols-1  lg:px-5 mx-auto  lg:gap-4 gap-7">
        {data.map((e, obj) => (
          <div className="group  rounded space-y-4 bg-white hover:bg-black hover:-translate-y-4 duration-500 p-8 shadow-md hover:shadow-xl">
            {e.img}
            <h2 className="group-hover:text-white font-semibold">{e.title}</h2>
            <p className="group-hover:text-white text-sm">{e.des}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplicationsTublar;
