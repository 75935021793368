import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import BlogDetail1 from "./pages/BlogDetail1";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import SolarProducts from "./pages/SolarProducts";
import Catalogue from "./pages/Catalogue";
import BlogDetail2 from "./pages/BlogDetail2";
import BlogDetail3 from "./pages/BlogDetail3";
import ERickshawProducts from "./pages/ERickshawProducts";
import AutomativeProducts from "./pages/AutomativeProducrs";
import TublarBattries from "./pages/TublarBatteries";
import InverterBatteries from "./pages/InverterBatteries";
import TermsConditions from "./components/TermsConditions";
import Privacypolicy from "./components/Privacypolicy";
import ScrollToTop from "./hooks/ScrollToTol";
import BlogDetail4 from "./pages/BlogDetail4";
import BlogDetail5 from "./pages/BlogDetail5";
import BlogDetail6 from "./pages/BlogDetail6";

function App() {
  return (
    <div className="App">
      <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-detail1" element={<BlogDetail1 />} />
        <Route path="/blog-detail2" element={<BlogDetail2 />} />
        <Route path="/blog-detail3" element={<BlogDetail3 />} />
        <Route path="/blog-detail4" element={<BlogDetail4 />} />
        <Route path="/blog-detail5" element={<BlogDetail5/>} />
        <Route path="/blog-detail6" element={<BlogDetail6/>} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/solar-flare-battery" element={<SolarProducts />} />
        <Route path="/e-rickshaw-battery" element={<ERickshawProducts />} />
        <Route path="/automotive-battery" element={<AutomativeProducts />} />
        <Route path="/catalogue" element={<Catalogue />} />
        <Route path="/powerful-tublar-batteries" element={<TublarBattries />} />
        <Route path="/inverter-batteries" element={<InverterBatteries />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
      </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;
