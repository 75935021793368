import React from "react";
import ContactFooter from "../components/common/ContactFooter";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import Banner from "../components/home/Banner";
import Model from "../components/products/Model";
import ERickshawBattery from "../components/rickshaw/ERickshawBattery";
import RickshawApplication from "../components/rickshaw/RickshawApplication";
import RickshawModel from "../components/rickshaw/RickshawModel";

const ERickshawProducts = () => {
  return (
    <>
      <Navbar />
      <Banner
        img="/assets/battery/erickshawbanner.jpg"
        pageTitle="From Place To Place"
        title="Roam without interruptions
        With long-running e-rickshaw batteries
        "
      />
      <ERickshawBattery/>
      <RickshawApplication/>
      <RickshawModel />
      <ContactFooter />
      <Footer />
    </>
  );
};

export default ERickshawProducts;
