import { useInView } from "framer-motion";
import React, { useRef } from "react";
import HeadingOnly from "../common/HeadingOnly";

const SolarTubular = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const data = [
    {
      id: 1,
      title: "Longer Backup",
      img: "/assets/icons/pro1.png",
    },
    {
      id: 2,
      title: "High Conductive Material",
      img: "/assets/icons/pro2.png",
    },
    {
      id: 3,
      title: "Less Evaporation",
      img: "/assets/icons/pro3.png",
    },
    {
      id: 4,
      title: "Low Internal Resistance",
      img: "/assets/icons/pro4.png",
    },
    {
      id: 5,
      title: "Leak Proof WLI",
      img: "/assets/icons/pro5.png",
    },
  ];
  return (
    <div className="container-ats pt-12 md:pb-20">
      <div className="md:px-0 px-6">
        <HeadingOnly title="SOLAR FLARE BATTERIES"/>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-7  ">
        <div className="col-span-4  space-y-4 pt-8 lg:px-0 px-5">
          <p className="text-justify">
          Our Solar Flare batteries are manufactured by Kamtech Solar Power Products Pvt. Ltd. and are built to endure deep discharges and high-temperature fluctuations. They need the least maintenance and are bearable to ultimate hot climates. These batteries are known for holding high voltage of power and as the best batteries for inverters.
          </p>
          <div className="lg:px-4  space-y-4">
            {data.map((e, obj) => (
              <div
                className="flex items-center gap-4"
                ref={ref}
                style={{
                  transform: isInView ? "none" : "translateX(200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
              >
                <img
                  src={e.img}
                  className="p-3 w-12 h-12 bg-primary rounded-full "
                />
                <h2 className="font-semibold">{e.title}</h2>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="col-span-3 relative">
          <div className="lg:w-96 right-0 top-10 lg:h-[40%] border-2 lg:border-primary rounded absolute  -z-10"></div>
          <img
            src="/assets/battery/solar-tubular.png"
            className="lg:w-full  bottom-40  pt-1 lg:flex hidden  absolute  right-5  "
          />
          <div className="lg:w-56 left-6  lg:h-36 border-2 lg:border-primary rounded absolute bottom-40 -z-10"></div>
        </div> */}
        <div className="col-span-3 relative flex items-center md:h-auto h-80 md:my-0 my-12 md:mx-0 mx-6">
          <img src="/assets/images/SolarTubularBattery.png" className="w-[90%] mx-auto md:pt-16"/>
          <div className="w-52 h-52 rounded bg-primary absolute md:top-20 top-0 right-0 -z-10 animate-bounce-medium"></div>
          <div className="md:w-96 w-72 h-40 rounded bg-black bottom-0 absolute -z-10 animate-bounce-medium"></div>
        </div>
      </div>
    </div>
  );
};

export default SolarTubular;
