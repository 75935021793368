import React, { useRef } from "react";
import { BiMobile } from "react-icons/bi";
import { MdLocationPin, MdEmail } from "react-icons/md";
import { useInView } from "framer-motion";
import { useFormik } from "formik";
import { contactSchema } from "../schemas";
import emailjs from "@emailjs/browser";

const ContactInfo = () => {
  return (
    <div className="flex flex-col space-y-3 z-10">
      <div className="md:flex md:gap-[66px]">
        <ContactDetail icon={BiMobile} title="Contact No." detail="+91-9650205622" />
        <ContactDetail icon={MdEmail} title="Email Id" detail="connect@varcasindustries.com" />
      </div>
      <div className="md:flex md:gap-[66px]">
      <ContactDetail icon={MdLocationPin} title="Registered Address:" detail="Jhansi, UP, India" />
      <ContactDetail icon={MdLocationPin} title="Export Office Address:" detail="Gurugram, Haryana, India" />
      </div>
      <div className="md:flex gap-4">
        <ContactDetail icon={BiMobile} title="Open Hours" detail="Monday - Friday 9:00AM - 5:00PM" />
      </div>
    </div>
  );
};

const ContactDetail = ({ icon: Icon, title, detail }) => (
  <p className="flex justify-start item-center gap-4 pb-6 text-sm">
    <span>
      <Icon size={20} className="w-12 h-12 pt-2 pb-2 rounded-full bg-[#Ec2028] text-white" />
    </span>
    <div className="flex flex-col">
      <span className="text-black font-bold">{title}</span>
      <span className="text-black">{detail}</span>
    </div>
  </p>
);

const ContactForm = ({ handleSubmit, values, errors, touched, handleChange }) => {
  return (
    <form onSubmit={handleSubmit} className="w-full md:flex md:justify-evenly z-10 relative lg:-bottom-[30px]">
      <div className="w-full lg:w-[80%] text-black rounded-xl border border-[#ffffff6a] bg-black lg:p-8 p-3 flex flex-col space-y-5 justify-center items-center">
        <FormField type="text" placeholder="Full Name" name="name" values={values} errors={errors} touched={touched} handleChange={handleChange} />
        <FormField type="text" placeholder="Company Name" name="company" values={values} errors={errors} touched={touched} handleChange={handleChange} />
        <div className="w-full px-2 py-2 bg-gray-50 border border-gray-300 rounded-md">
          <select id="countries" className="text-sm text-gray-500 rounded-lg block w-full outline-none" name="country" value={values.country} onChange={handleChange}>
            <option selected>Choose a country</option>
            {/* Add more country options here */}
          </select>
          {errors.country && touched.country && <p className="text-xs italic text-white pt-1">{errors.country}</p>}
        </div>
        <FormField type="text" placeholder="Phone Number" name="phone" values={values} errors={errors} touched={touched} handleChange={handleChange} />
        <FormField type="email" placeholder="Email Address" name="email" values={values} errors={errors} touched={touched} handleChange={handleChange} />
        <FormField as="textarea" placeholder="Message" name="message" values={values} errors={errors} touched={touched} handleChange={handleChange} />
        <button type="submit" className="btn-primary">Submit</button>
      </div>
    </form>
  );
};

const FormField = ({ as: Component = "input", type, placeholder, name, values, errors, touched, handleChange }) => (
  <div className="w-full">
    <Component
      type={type}
      className="input-glassmorphics"
      placeholder={placeholder}
      name={name}
      value={values[name]}
      onChange={handleChange}
    />
    {errors[name] && touched[name] && <p className="text-xs italic text-white pt-1">{errors[name]}</p>}
  </div>
);

const Contactbanner = () => {
  const initialValues = {
    name: "",
    company: "",
    country: "",
    phone: "",
    email: "",
    message: "",
  };
  
  const ref = useRef(null);
  const isInView = useInView(ref);

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: contactSchema,
    onSubmit: (values, actions) => {
      console.log(values);
      emailjs.send("atssolar", "contactformatssolar", values, "DEjMVJNuIh-WSF70y").then(
        (result) => {
          console.log(result);
          alert("Successfully Submitted Your Response");
        },
        (error) => {
          console.log(error.text);
        }
      );
      actions.resetForm();
    },
  });

  return (
    <div className="bg-white relative lg:px-0 px-5 min-h-screen mb-10">
      <div className="container-ats">
        <img src="/assets/images/grid.png" alt="" className="absolute -left-10 bottom-20" />
        <img src="/assets/images/ellipse.png" alt="" className="absolute right-0 bottom-0" />
        <div className="grid lg:grid-cols-2 justify-between container-default pt-[40px] relative items-center">
          <div className="flex flex-col space-y-3 z-10">
            <h3 className="text-3xl text-black font-bold">Contact Us</h3>
            <div
              className="h-1 w-36 bg-primary"
              ref={ref}
              style={{
                transform: isInView ? "none" : "translateX(200px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              }}
            />
            <p className="text-black text-sm leading-relaxed text-justify lg:w-[80%] w-full py-6">
              Reach out to our experts to find out more about us and our batteries. Get your master battery to lighten up your world.
            </p>
            <ContactInfo />
          </div>
          <ContactForm handleSubmit={handleSubmit} values={values} errors={errors} touched={touched} handleChange={handleChange} />
        </div>
      </div>
    </div>
  );
};

export default Contactbanner;
