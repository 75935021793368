import React from "react";
import Heading from "../common/Heading";

const DimensionsTublar = () => {
  return (
    <div className="lg:py-16 py-6 space-y-8 container-ats">
      <div className="">
        <Heading
          title="DIMENSIONS OF OUR BATTERY"
          description="Fit our batteries perfectly with your space as we build to add smoothness to your power supply."
        />
      </div>
      <div className=" grid grid-cols-1 lg:grid-cols-3 gap-2 px-5">
        <img
          src="/assets/images/dimOne.png"
          className="hover:-translate-y-3 duration-200"
        />
        <img
          src="/assets/images/dimTwo.png"
          className="hover:-translate-y-3 duration-200"
        />
        <img
          src="/assets/images/dimThree.png"
          className="hover:-translate-y-3 duration-200"
        />
      </div>
    </div>
  );
};

export default DimensionsTublar;
