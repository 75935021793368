import React from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";

const Catalogue = () => {
  return (
    <>
      <Navbar />
      <div className="pt-20 bg-black">
        <embed
          src="/assets/images/ATS_Catalog_SpecSheet.compressed copy.pdf"
          className="w-100 w-full h-[2160px]"
        />
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default Catalogue;
