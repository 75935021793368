import React from "react";
import Heading from "../common/Heading";
import { RiTeamFill } from "react-icons/ri";

import { GiArrowsShield, GiEcology } from "react-icons/gi";
import { FaSolarPanel } from "react-icons/fa";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const Story = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const textVariants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 2.5,
      },
    },
  };

  const data = [
    {
      id: 1,
      img: <GiArrowsShield size={70} color="white" />,
      title:
        " We follow a rigid design and body to work under high pressure and circumstances.",
    },
    {
      id: 1,
      img: <GiEcology size={70} color="white" />,
      title:
        "We have eco-friendly batteries with the least care needed to make the supply smooth.",
    },
    {
      id: 1,
      img: <FaSolarPanel size={70} color="white" />,
      title:
        "ATS Batteries started to bring uninterrupted electricity available for all at every time.        ",
    },
    {
      id: 1,
      img: <RiTeamFill size={70} color="white" />,
      title:
        "We have a team of experienced and dedicated employees working to their full potential to stand out on their commitment.        ",
    },
  ];
  return (
    <>
      <div className="  container-ats">
        <Heading
          title="OUR STORY"
          description="ATS Batteries is a company under Varcas Industries Pvt. Ltd. We are building batteries to drive the household, factories, and power plants, with the power of electricity."
        />

        <motion.div
          ref={ref}
          animate={controls}
          variants={textVariants}
          initial={{ y: -20, opacity: 0 }}
          transition={{ duration: 4000 }}
        >
          {" "}
          <div className=" grid lg:grid-cols-2  lg:px-0 px-5  lg:pt-12  pb-16 gap-16 lg:mb-0 lg:mt-0 mb-10 mt-8">
            {data.map((e, obj) => (
              <div className="grid grid-cols-6 h-36 lg:h-32 group hover:-translate-y-4 duration duration-500 hover:drop-shadow-xl">
                <div className="col-span-2 bg-black group-hover:bg-primary flex justify-center items-center rounded">
                  {e.img}
                </div>
                <div className="space-y-4 py-4 px-6 bg-[#F1F1F1] col-span-4 rounded">
                  <h2 className="font-semibold text-justify tracking-tighter">
                    {e.title}
                  </h2>
                  <p className="text-sm text-justify">{e.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Story;
