import React from "react";
import Heading from "../common/Heading";

const RickshawModel = () => {
  const data = [
    {
      id: 1,
      model: "ATSP150MINI",
      capacity: "150 Ah",
      // warranty: "6 Months ",
      l: "410",
      w: "175",
      h: "210",
    },
    {
      id: 2,
      model: "ATSP1606J",
      capacity: "160 Ah",
      // warranty: "6 Months ",
      l: "410",
      w: "175",
      h: "250 ",
    },
    {
      id: 3,
      model: "ATSP1809T",
      capacity: "180 Ah",
      // warranty: "9 Months ",
      l: "410",
      w: "175",
      h: "250",
    },
  ];
  return (
    // space-y-8 py-8

    <div className="space-y-8 py-8">
      <div className="container-ats">
        <Heading
          title="OUR MODELS @C20"
          description="We fit all your journeys and conditions with a robust design and body. They are well-suited for all the e-rickshaws."
        />
      </div>

      {/* container-ats */}
      {/* grid grid-cols-1 lg:grid-cols-5 */}

      <div className="container-ats overflow-auto">
        <table className="table-auto w-full ">
          <thead>
            <tr>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                S.No.
              </th>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Model
              </th>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Capacity Ah @ C20
              </th>
              {/* <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Warranty
              </th> */}

              <th
                colSpan="3"
                className="border-b-2 border-white bg-black text-white p-4 rounded"
              >
                Dimension(mm)
              </th>
            </tr>
            <tr>
              <th className=" border-r-2 border-white bg-black text-white p-4 rounded">
                L
              </th>
              <th className="border-r-2 border-white bg-black text-white p-4 rounded">
                W
              </th>
              <th className="border-r-2 border-white bg-black text-white p-4 rounded">
                H
              </th>
            </tr>
          </thead>
          <tbody className="">
            {data.map((e, obj) => (
              <tr className="">
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.id}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.model}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.capacity}
                </td>
                {/* <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.warranty}
                </td> */}
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.l}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.w}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.h}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RickshawModel;
