import { useInView } from "framer-motion";
import React, { useRef } from "react";
import HeadingOnly from "../common/HeadingOnly";
import { MdOutlineLowPriority, MdOutlineViewCompact } from "react-icons/md";
import { GiBatteryMinus, GiWeight } from "react-icons/gi";
import { TbHourglassHigh } from "react-icons/tb";
import { RiUserSettingsFill } from "react-icons/ri";

const AutomativeBattery = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const data = [
    {
      id: 1,
      title: "Less Maintenance",
      img: <RiUserSettingsFill size={24} />,
    },
    {
      id: 2,
      title: "Low Discharging Rate      ",
      img: <GiBatteryMinus size={24} />,
    },
    {
      id: 3,
      title: "Compact Size",
      img: <MdOutlineViewCompact size={24} />,
    },
    {
      id: 4,
      title: "High Performance",
      img: <TbHourglassHigh size={24} />,
    },
    {
      id: 5,
      title: "Light weight",
      img: <GiWeight size={24} />,
    },
  ];
  return (
    <div className="container-ats pt-12 md:pb-20">
      <div className="md:px-0 px-6">
        <HeadingOnly title="AUTOMOTIVE BATTERY" />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-7">
        <div className="col-span-4  space-y-4 pt-8 lg:px-0 px-5">
          {/* <HeadingOnly title="AUTOMOTIVE BATTERY" /> */}
          <p className="text-justify">
            Have you ever gotten in trouble with your automotive battery? If
            yes, you must have gone through all that hassles and sudden stress
            to move your vehicle without getting it in much trouble. To avoid
            these conditions, you must get your hands on fast charging and long
            power reserve automotive batteries by ATS Batteries.
          </p>
          <div className="lg:p-4 space-y-4">
            {data.map((e, obj) => (
              <div
                className="flex items-center gap-4"
                ref={ref}
                style={{
                  transform: isInView ? "none" : "translateX(200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
              >
                <div className="p-3 text-white bg-primary rounded-full ">
                  {e.img}
                </div>
                <h2 className="font-semibold">{e.title}</h2>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="col-span-3 relative">
          <div className="lg:w-96 right-0 top-10 lg:h-[40%] border-2 lg:border-primary rounded absolute  -z-10"></div>
          <img
            src="/assets/battery/Car.png"
            className="lg:w-full  bottom-28  pt-1 lg:flex hidden  absolute  right-5  "
          />
          <div className="lg:w-56 left-6  lg:h-36 border-2 lg:border-primary rounded absolute bottom-32 -z-10"></div>
        </div> */}
        <div className="col-span-3 relative flex items-center md:h-auto h-80 md:my-0 my-12 md:mx-0 mx-6">
          <img
            src="/assets/images/Automotive-battery.webp"
            className="w-[90%] mx-auto md:pt-16"
          />
          <div className="w-52 h-52 rounded bg-primary absolute md:top-20 top-0 right-0 -z-10 animate-bounce-medium"></div>
          <div className="md:w-96 w-72 h-40 rounded bg-black bottom-0 absolute -z-10 animate-bounce-medium"></div>
        </div>
      </div>
    </div>
  );
};

export default AutomativeBattery;
