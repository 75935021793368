import React from "react";
import ContactFooter from "../components/common/ContactFooter";
import { NavLink } from "react-router-dom";
import Footer from "../components/common/Footer";
import { BsArrowLeft } from "react-icons/bs";
import Navbar from "../components/common/Navbar";
import { BsArrowRight } from "react-icons/bs";
import Banner from "../components/home/Banner";

const BlogDetail3 = () => {
  return (
    <div>
      <Navbar />
      <Banner
        img="/assets/battery/banner-blog.png"
        pageTitle="GET IN TOUCH WITH US"
        title="Learn And Grow"
        des="Grab The Power Of Knowledge, With The Power Of ATS Solar"
      />

      <div className="lg:px-32  px-5">
        <div className=" pt-10 relative">
          <NavLink to="/blog">
            <div className="flex gap-3 py-2">
              <BsArrowLeft className="pt-1 w-6 h-6" />
              <p className="font-semibold ">Back</p>
            </div>
          </NavLink>
          <img
            src="assets/battery/img-3.png"
            className="w-full  h-[450px] bg-black/80 bg-blend-overlay"
          />

          <div className="absolute lg:bottom-16 bottom-10 lg:px-12 px-5 bg-black py-6 bg-opacity-50 rounded">
            <div className="flex gap-5">
              <img src="assets/images/star.svg" />

              <p className="text-white">Top Story</p>
              <p className="text-white">|</p>
              <p className="text-white">4 min read</p>
            </div>

            <h4 className="text-white pt-3 lg:text-3xl font-bold  w-full">
              What does your heavy-duty battery need?
            </h4>

            <div className="flex gap-4">
              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/eye.svg" />
                <p className="text-white text-sm font-semibold">123k Views</p>
                <p className="text-white">|</p>
              </div>

              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/heart-icon.svg" />
                <p className="text-white text-sm font-semibold">10k Likes</p>
              </div>
            </div>
          </div>
        </div>
        <h6 className="font-semibold pt-4">
          By : Keerti Vashishth | 16th july 2022
        </h6>
        <p className="text-justify py-5 leading-loose">
          Are you also tired of your heavy car batteries? There are different
          sizes of batteries for different sizes of vehicles, and they require
          some regular maintenance and routine. The term "heavy duty" refers to
          items intended to handle difficult work or tasks; a product that is
          built to be stronger and provide more power than standard ones. These
          batteries aremulti-functional and can perform various functions at the
          same time. Follow these steps to obtain a continuous power source from
          them and keep things running smoothly.
          <br />
          The availability of many types of industrial batteries signifies that
          not every battery is designed for the same purpose.
        </p>

        <ul className="pl-8">
          <li className="list-disc text-justify py-2 leading-loose">
            <span className="font-bold">
              {" "}
              Strong Starting Power: The industrial sector necessitates the use
              of batteries with high starting power.{" "}
            </span>
            Thus, the supply needs to offer a strong starting power for heavy
            functions and massive power needs.
          </li>

          <li className="list-disc text-justify py-2 leading-loose">
            <span className="font-bold text-lg"> Adaptable: </span>
            The heavy-duty battery should be able to handle and endure changes
            in various climatic conditions.
          </li>

          <li className="list-disc text-justify py-2 leading-loose">
            <span className="font-bold text-lg">Durable: </span>
            The batteries need to withstand constant jarring and high usage.
          </li>

          <li className="list-disc text-justify py-2 leading-loose">
            <span className="font-bold text-lg"> Long Product Life:</span>A long
            product life is essential to effectively manage operational costs.
          </li>
        </ul>

        <p className="text-justify py-5 leading-loose">
          While selecting the battery for your operations, one needs to be
          precise and careful as even a great brand might fail to fulfil your
          requirements sometimes. Find a battery that offers consistent power,
          incredible durability, long-lasting performance, and long battery
          life. See what factors you need to cross-check while buying a battery.
        </p>

        <ul className="pl-8">
          <li className="list-disc text-justify py-2 leading-loose">
            <span className="font-bold text-lg"> Battery Construction:</span> It
            is important to understand the construction of the battery to
            decipher its performance with high energy demands. ATS Batteries is
            constructing a design based on the latest technology.
          </li>

          <li className="list-disc text-justify py-2 leading-loose">
            <span className="font-bold text-lg">Battery Components:</span> The
            materials and their quality will determine the overall quality and
            performance of the finished product. Therefore, it is better to look
            a little deeper into the component build-up even if it calls for an
            extra expense.
          </li>

          <li className="list-disc text-justify py-2 leading-loose">
            <span className="font-bold text-lg">Battery Capacity:</span>{" "}
            Capacity refers to the amount of discharge current a battery can
            deliver over time and is measured in ampere-hours (Ah). The higher
            the Ah, the longer a charged battery will last.
          </li>
        </ul>

        <p className="text-justify py-5 leading-loose">
          ATS Batteries is leading the power industry at a small level currently
          with their low maintenance and long power backup batteries that are
          environmentally and pocket-friendly. We aim to lead the industry at a
          global level and fulfil the endless demand for power. Your heavy-duty
          battery needs to be selected from a trusted name and meet the required
          specifications.
        </p>
      </div>

      <div className="lg:px-32 px-5 pb-20 pt-5">
        <div className="lg:grid grid-cols-2 gap-10">
          <div>
            <img
              src="assets/battery/blog-set5.jpg"
              className="h-80 w-full rounded"
            />
          </div>
          <div className="lg:pt-0 pt-8">
            <img
              src="assets/battery/blog-set9.jpg"
              className="h-80 w-full rounded"
            />
          </div>
        </div>
      </div>

      <ContactFooter />
      <Footer />
    </div>
  );
};

export default BlogDetail3;
