import React, { useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import ContactFooter from "../components/common/ContactFooter";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import Contactheading from "../components/contact/Contactheading";
import Banner from "../components/home/Banner";
import { useInView } from "framer-motion";
import Heading from "../components/common/Heading";

const Blog = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { threshold: 0.5 });

  return (
    <div>
      <Navbar />

      <Banner
        img="/assets/images/aboutbg.png"
        pageTitle="GET IN TOUCH WITH US"
        title="Learn And Grow"
        des="Grab The Power Of Knowledge, With The Power Of ATS Batteries"
      />

      {/* latest blogs */}
      <div className="container-ats py-10 ">
        <div>
          <Heading
            title="Latest Blogs"
            description="
            Know more about the industry and the market of batteries. Read, Learn, and get lit up with knowledge and ATS Batteries."
          />
        </div>
        <div className="lg:grid lg:grid-cols-12 pt-8 lg:gap-3 lg:px-0 px-5 ">
          <div className="lg:col-span-5 relative group overflow-hidden">
            <img
              src="assets/battery/latest-blog1.png"
              className="w-full  h-full rounded group-hover:scale-105 duration-300"
            />

            <div className="absolute lg:bottom-4 bottom-0.5 lg:px-8 px-5">
              <div className="flex gap-5">
                <img src="assets/images/star.svg" />

                <p className="text-white">Top Story</p>
                <p className="text-white">|</p>
                <p className="text-white">4 min read</p>
              </div>

              <h4 className="text-white pt-3 lg:text-lg font-bold  pb-4   w-full">
                Tips and Tricks to find the best inverter battery for your house
              </h4>

              {/* <div className="flex gap-4">
                <div className="flex gap-2 pt-3 items-center justify-center">
                  <img src="assets/images/eye.svg" />
                  <p className="text-white text-sm font-semibold">123k Views</p>
                  <p className="text-white">|</p>
                </div>

                <div className="flex gap-2 pt-3 items-center justify-center">
                  <img src="assets/images/heart-icon.svg" />
                  <p className="text-white text-sm font-semibold">10k Likes</p>
                </div>
              </div> */}

              <p className="text-sm text-white">
                If you are planning to buy an inverter battery for your
                household, then there are several things you should consider. It
                is very important to understand what the right inverter is for
                your home.
              </p>

              <NavLink to="/blog-detail1">
                {" "}
                <p className="text-primary text-sm px-6 font-semibold py-3">
                  Read More+
                </p>
              </NavLink>
            </div>
          </div>

          <div className="col-span-7">
            <div className="lg:flex gap-3">
              <div className="relative lg:pt-0 pt-3 group">
                <img
                  src="assets/battery/latest-blog-02.png"
                  className="w-full  h-full rounded group-hover:scale-105 duration-300"
                />

                <div className="absolute lg:bottom-4 bottom-10 lg:px-8 px-5">
                  <div className="flex gap-5">
                    <img src="assets/images/star.svg" />

                    <p className="text-white">Top Story</p>
                    <p className="text-white">|</p>
                    <p className="text-white">4 min read</p>
                  </div>

                  <Link to="/blog-detail2">
                    {" "}
                    <h4 className="text-white pt-3 lg:text-lg font-bold  pb-4   w-full">
                      How to Take Care of Your Inverter Battery
                    </h4>
                  </Link>

                  {/* <div className="flex gap-4">
                    <div className="flex gap-2 pt-3 items-center justify-center">
                      <img src="assets/images/eye.svg" />
                      <p className="text-white text-sm font-semibold">
                        123k Views
                      </p>
                      <p className="text-white">|</p>
                    </div>

                    <div className="flex gap-2 pt-3 items-center justify-center">
                      <img src="assets/images/heart-icon.svg" />
                      <p className="text-white text-sm font-semibold">
                        10k Likes
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="relative lg:pt-0 pt-3 group">
                <img
                  src="assets/battery/latest-blog-3.png"
                  className="w-full  h-full rounded group-hover:scale-105 duration-300"
                />

                <div className="absolute lg:bottom-4 bottom-10 lg:px-8 px-5">
                  <div className="flex gap-5">
                    <img src="assets/images/star.svg" />

                    <p className="text-white">Top Story</p>
                    <p className="text-white">|</p>
                    <p className="text-white">4 min read</p>
                  </div>

                  <Link to="/blog-detail3">
                    {" "}
                    <h4 className="text-white pt-3 lg:text-lg font-bold  pb-4   w-full">
                      What does your heavy-duty battery need?
                    </h4>
                  </Link>

                  {/* <div className="flex gap-4">
                    <div className="flex gap-2 pt-3 items-center justify-center">
                      <img src="assets/images/eye.svg" />
                      <p className="text-white text-sm font-semibold">
                        123k Views
                      </p>
                      <p className="text-white">|</p>
                    </div>

                    <div className="flex gap-2 pt-3 items-center justify-center">
                      <img src="assets/images/heart-icon.svg" />
                      <p className="text-white text-sm font-semibold">
                        10k Likes
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="lg:flex gap-3 pt-2">
              <div className="relative group">
                <img
                  src="assets/battery/latest-blog-5.png"
                  className="w-full  h-full rounded group-hover:scale-105 duration-300"
                />

                <div className="absolute lg:bottom-4 bottom-10 lg:px-8 px-5">
                  <div className="flex gap-5">
                    <img src="assets/images/star.svg" />

                    <p className="text-white">Top Story</p>
                    <p className="text-white">|</p>
                    <p className="text-white">4 min read</p>
                  </div>

                  <Link to="/blog-detail2">
                    {" "}
                    <h4 className="text-white pt-3 lg:text-lg font-bold  pb-4   w-full">
                      Keep your inverter battery fit for long-life running
                    </h4>
                  </Link>

                  {/* <div className="flex gap-4">
                    <div className="flex gap-2 pt-3 items-center justify-center">
                      <img src="assets/images/eye.svg" />
                      <p className="text-white text-sm font-semibold">
                        123k Views
                      </p>
                      <p className="text-white">|</p>
                    </div>

                    <div className="flex gap-2 pt-3 items-center justify-center">
                      <img src="assets/images/heart-icon.svg" />
                      <p className="text-white text-sm font-semibold">
                        10k Likes
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="relative group lg:pt-0 pt-3">
                <img
                  src="assets/battery/latest-blog-4.png"
                  className="w-full  h-80 rounded group-hover:scale-105 duration-300"
                />

                <div className="absolute lg:bottom-4 bottom-10 px-8">
                  <div className="flex gap-3">
                    <img src="assets/images/star.svg" />

                    <p className="text-white">Top Story</p>
                    <p className="text-white">|</p>
                    <p className="text-white">4 min read</p>
                  </div>

                  <Link to="/blog-detail2">
                    {" "}
                    <h4 className="text-white pt-3 lg:text-lg font-bold pb-4  w-full">
                      How to Take Care of Your Inverter Battery
                    </h4>
                  </Link>

                  {/* <div className="flex gap-4">
                    <div className="flex gap-2 pt-3 items-center justify-center">
                      <img src="assets/images/eye.svg" />
                      <p className="text-white text-sm font-semibold">
                        123k Views
                      </p>
                      <p className="text-white">|</p>
                    </div>

                    <div className="flex gap-2 pt-3 items-center justify-center">
                      <img src="assets/images/heart-icon.svg" />
                      <p className="text-white text-sm font-semibold">
                        10k Likes
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-[2px] bg-[#000] container-ats mt-8 "></div>

      {/* top blogs */}
      <div className="container-ats">
        <div className="py-10">
          <Heading
            title="Our Top Blogs"
            description="Know more about the industry and the market of batteries. Read, Learn, and get lit up with knowledge and ATS Batteries."
          />
        </div>
      </div>

      <div className="grid lg:grid-cols-3 container-ats gap-4 lg:px-0 px-5 pb-10">
        <div className="rounded-md pb-4 shadow-box">
          <div className="flex">
            <p className="lg:text-7xl  text-6xl font-semibold text-[#BFBFBF] lg:pl-1">
              01
            </p>
            <img
              src="assets/battery/b-img4.png"
              className=" h-[90%] lg:w-80 lg:h-60 w-[79%] pt-10"
            />
          </div>

          <div className="">
            <h4 className=" pt-6 lg:text-lg font-bold  px-6 w-full">
              The Evolution of Tubular Batteries and Their Impact on Modern
              Energy Solutions
            </h4>

            {/* <div className="flex gap-4 pt-2 pb-3">
              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/eye.svg" />
                <p className="text-sm font-semibold">123k Views</p>
                <p className="">|</p>
              </div>

              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/heart-icon.svg" />
                <p className=" text-sm font-semibold">10k Likes</p>
              </div>
            </div> */}

            <p className="text-justify px-6 text-sm">
              The quest for reliable energy storage solutions has been a driving force in technological
              advancement for decades. Tubular batteries have emerged as a critical component in this
              arena, offering unmatched reliability and efficiency. This blog explores the evolution of
              tubular batteries, their technological advancements, and their impact on modern energy
              solutions.

            </p>

            <NavLink to="/blog-detail4">
              {" "}
              <p className="text-primary text-sm px-6 font-semibold py-3">
                Read More+
              </p>
            </NavLink>
          </div>
        </div>

        <div className="rounded-md pb-4 shadow-box">
          <div className="flex">
            <p className="lg:text-7xl  text-6xl font-semibold text-[#BFBFBF] lg:pl-1">
              02
            </p>
            <img
              src="assets/battery/b-img5.png"
              className=" h-[90%] lg:w-80 lg:h-60 w-[79%] pt-10"
            />
          </div>

          <div className="">
            <h4 className=" pt-6 lg:text-lg font-bold  px-6 w-full">
              Installing and Maintaining Tubular Batteries: Best Practices for
              Longevity and Efficiency
            </h4>

            {/* <div className="flex gap-4 pt-2 pb-3">
              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/eye.svg" />
                <p className="text-sm font-semibold">123k Views</p>
                <p className="">|</p>
              </div>

              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/heart-icon.svg" />
                <p className=" text-sm font-semibold">10k Likes</p>
              </div>
            </div> */}

            <p className="text-justify px-6 text-sm">
              Tubular batteries are renowned for their durability and long service life, making them a
              popular choice for various applications. Proper installation and maintenance are crucial to
              maximizing their performance and ensuring longevity. This blog outlines best practices for
              installing and maintaining tubular batteries to optimize efficiency and reliability.

            </p>

            <NavLink to="/blog-detail5">
              {" "}
              <p className="text-primary text-sm px-6 font-semibold py-3">
                Read More+
              </p>
            </NavLink>
          </div>
        </div>

        <div className="rounded-md pb-4 shadow-box">
          <div className="flex">
            <p className="lg:text-7xl  text-6xl font-semibold text-[#BFBFBF] lg:pl-1">
              03
            </p>
            <img
              src="assets/battery/b-img6.png"
              className=" h-[90%] lg:w-80 lg:h-60 w-[79%] pt-10"
            />
          </div>

          <div className="">
            <h4 className=" pt-6 lg:text-lg font-bold  px-6 w-full">
              How Tubular Batteries Revolutionize Backup Power Systems
            </h4>

            {/* <div className="flex gap-4 pt-2 pb-3">
              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/eye.svg" />
                <p className="text-sm font-semibold">123k Views</p>
                <p className="">|</p>
              </div>

              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/heart-icon.svg" />
                <p className=" text-sm font-semibold">10k Likes</p>
              </div>
            </div> */}

            <p className="text-justify px-6 text-sm">
              In today's fast-paced world, the demand for reliable backup power systems has never been
              higher. Tubular batteries have emerged as a revolutionary solution, providing dependable
              power in various applications. This blog delves into how tubular batteries are transforming
              backup power systems, their benefits, and their applications.

            </p>

            <NavLink to="/blog-detail6">
              {" "}
              <p className="text-primary text-sm px-6 font-semibold py-3">
                Read More+
              </p>
            </NavLink>
          </div>
        </div>

        <div className="rounded-md pb-4 shadow-box">
          <div className="flex">
            <p className="lg:text-7xl  text-6xl font-semibold text-[#BFBFBF] lg:pl-1">
              04
            </p>
            <img
              src="assets/battery/b-img3.png"
              className=" h-[90%] lg:w-80 lg:h-60 w-[79%] pt-10"
            />
          </div>

          <div className="">
            <h4 className=" pt-6 lg:text-lg font-bold  px-6 w-full">
              Tips and Tricks to find the best inverter battery for your house?
            </h4>

            {/* <div className="flex gap-4 pt-2 pb-3">
              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/eye.svg" />
                <p className="text-sm font-semibold">123k Views</p>
                <p className="">|</p>
              </div>

              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/heart-icon.svg" />
                <p className=" text-sm font-semibold">10k Likes</p>
              </div>
            </div> */}

            <p className="text-justify px-6 text-sm">
              If you are planning to buy an inverter battery for your household,
              then there are several things you should consider. It is very
              important to understand what the right inverter is for your home.
            </p>

            <NavLink to="/blog-detail1">
              {" "}
              <p className="text-primary text-sm px-6 font-semibold py-3">
                Read More+
              </p>
            </NavLink>
          </div>
        </div>

        <div className="rounded-md pb-4 shadow-box">
          <div className="flex ">
            <p className="lg:text-7xl text-6xl font-semibold text-[#BFBFBF] lg:pl-1">
              05
            </p>
            <img
              src="assets/battery/b-img2.png"
              className="h-[90%] lg:w-80 lg:h-60 w-[79%] pt-10"
            />
          </div>

          <div className="">
            <h4 className=" pt-6 lg:text-lg font-bold  px-6 w-full">
              How to Take Care of Your Inverter Battery?
            </h4>
            {/* 
            <div className="flex gap-4 pt-2 pb-3">
              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/eye.svg" />
                <p className="text-sm font-semibold">123k Views</p>
                <p className="">|</p>
              </div>

              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/heart-icon.svg" />
                <p className=" text-sm font-semibold">10k Likes</p>
              </div>
            </div> */}

            <p className="text-justify px-6 text-sm">
              The batteries are the soul of the inverter as they are responsible
              for functioning and fulfilling the core task. Therefore, routine
              maintenance and upkeep are very important for the battery’s
              performance.
            </p>

            <NavLink to="/blog-detail2">
              {" "}
              <p className="text-primary text-sm px-6 font-semibold py-3">
                Read More+
              </p>
            </NavLink>
          </div>
        </div>

        <div className="rounded-md pb-4 shadow-box">
          <div className="flex">
            <p className="lg:text-7xl  text-6xl font-semibold text-[#BFBFBF] lg:pl-1">
              06
            </p>
            <img
              src="assets/battery/b-img1.png"
              className=" h-[90%] lg:w-80 lg:h-60 w-[79%] pt-10 "
            />
          </div>

          <div className="">
            <h4 className=" pt-6 lg:text-lg font-bold  px-6 w-full">
              What does your heavy-duty battery need?
            </h4>
            {/* 
            <div className="flex gap-4 pt-2 pb-3">
              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/eye.svg" />
                <p className="text-sm font-semibold">123k Views</p>
                <p className="">|</p>
              </div>

              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/heart-icon.svg" />
                <p className=" text-sm font-semibold">10k Likes</p>
              </div>
            </div> */}

            <p className="text-justify px-6 text-sm">
              Are you also tired of your heavy car batteries? There are
              different sizes of batteries for different sizes of vehicles, and
              they require some regular maintenance and routine.
            </p>

            <NavLink to="/blog-detail3">
              {" "}
              <p className="text-primary text-sm px-6 font-semibold py-3">
                Read More+
              </p>
            </NavLink>
          </div>
        </div>
      </div>
      {/* <div className="px-16 pt-3 pb-16">
        <p className="text-primary text-right font-semibold">View More+</p>
      </div> */}

      <ContactFooter />
      <Footer />
    </div>
  );
};

export default Blog;
