import React from "react";
import Heading from "../common/Heading";
import { FaHome, FaSolarPanel } from "react-icons/fa";
import { GiSolarPower } from "react-icons/gi";

const Application = () => {
  const data = [
    {
      id: 1,
      img: <FaHome className="w-12 h-12 text-primary group-hover:text-white" />,
      title: "Solar Systems for Home",
      des: "Upgrade your household power supply with an effective, efficient, and eco-friendly solar battery. They provide long backups and require negligible maintenance to run all your home appliances smoothly without interruptions.       ",
    },
    {
      id: 2,
      img: (
        <GiSolarPower className="w-12 h-12 text-primary group-hover:text-white" />
      ),
      title: "Solar Systems for Shops & Office",
      des: "Thick tubular plates deliver excellent power backups and reserve volumes with less need for top-ups. They can withstand frequent and prolonged power cuts. Therefore your employees, clients, and customers won't face any trouble due to power loss.       ",
    },
    {
      id: 3,
      img: (
        <FaSolarPanel className="w-12 h-12 text-primary group-hover:text-white" />
      ),
      title: "Solar Systems for Small Industries",
      des: "All sized industries have massive requirements for power, and even a tiny breakage can cost you a loss equal to a whole day. Our ATS Batteries Flare Batteries are a one-time solution with little maintenance and hour-long power backups.",
    },
  ];
  return (
    <div className="px-4 bg-secondary lg:space-y-16 space-y-8 py-12  ">
      <div className="container-ats">
        <Heading
          title="OUR APPLICATION"
          description="Our solar flare batteries are an assurance with superior performance and high reliability. They require ultra-low maintenance and provide long power backups."
        />
      </div>
      <div className="grid lg:grid-cols-3  grid-cols-1 container-ats lg:px-5 mx-auto  lg:gap-4 gap-7">
        {data.map((e, obj) => (
          <div className="group rounded space-y-4 bg-white hover:bg-black hover:-translate-y-4 duration-500 p-8 shadow-md hover:shadow-xl">
            {e.img}
            <h2 className="group-hover:text-white font-semibold">{e.title}</h2>
            <p className="group-hover:text-white text-sm">{e.des}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Application;
