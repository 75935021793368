import React from "react";
import Heading from "../common/Heading";
import HeadingOnly from "../common/HeadingOnly";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const Mission = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const textVariants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
      },
    },
  };

  const data = [
    {
      id: 1,
      img: "/assets/icons/m1.png",
      title: "OUR MISSION",
      des: "To increase the positive competition in the market by bringing in excellence and exceeding expectations.      ",
    },
    {
      id: 2,
      img: "/assets/icons/m2.png",
      title: "OUR VISION",
      des: "To become a reputable brand in the power industry known for quality batteries across the globe.      ",
    },
    {
      id: 3,
      img: "/assets/icons/m3.png",
      title: "OUR VALUES",
      des: "To be the trusted power partners to our customers, investors, and stakeholders by delivering high-quality ATS batteries.      ",
    },
  ];
  return (
    <div className="lg:py-16 py-10 container-ats">
      <div className="">
        <Heading
          title="Mission, Vision & Values"
          description="Every company stands on its mission, vision, and values along with its team and services. Ours defines us from the ground up for our people and our mission. "
        />
      </div>
      <motion.div
        ref={ref}
        animate={controls}
        variants={textVariants}
        initial={{ y: -30, opacity: 0 }}
        transition={{ duration: 4000 }}
      >
        <div className=" lg:pt-12 lg:pb-4 pt-6 pb-2 ">
          {/* <div className="space-y-4">
        <HeadingOnly title="Mission, Vision & Values" />
        <p className="max-w-xl">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make atype specimen book.
        </p>
      </div> */}

          <div className="grid lg:grid-cols-3 lg:gap-16 gap-6 grid-cols-1 lg:px-0 px-5">
            {data.map((e, obj) => (
              <div
                className="flex lg:gap-4 md:gap-6 gap-5  lg:py-0 py-4 "
                key={obj}
              >
                <img src={e.img} className="w-20 h-20" />
                <div className="space-y-2">
                  <h2 className="underline underline-offset-8 decoration-primary font-bold">
                    {e.title}
                  </h2>
                  <p className="text-sm text-justify">{e.des}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Mission;
