import React from "react";
import Faq from "../components/about/Faq";
import Mission from "../components/about/Mission";
import OurLeader from "../components/about/OurLeader";
import Story from "../components/about/Story";
import WhatSolution from "../components/about/WhatSolution";
import ContactFooter from "../components/common/ContactFooter";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import Banner from "../components/home/Banner";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div className="overflow-x-hidden">
      <Navbar />

      <div
        className="w-full  bg-no-repeat   bg-center bg-cover rounded-sm h-[60vh] relative mt-[80px]"
        style={{ backgroundImage: `url(${"/assets/images/aboutbg.png"})` }}
      >
        <div className="w-full flex justify-end flex-col absolute bottom-20 lg:px-20 px-5">
          <div className="flex items-center gap-5">
            <div className="h-10 w-[0.15rem] bg-primary"></div>

            <motion.p
              initial={{ x: -100, opacity: 0 }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  duration: 2,
                },
              }}
              transition={{ duration: 1000 }}
            >
              <p className="text-sm  text-primary drop-shadow-2xl font-semibold tracking-widest">
                Break The Barrier
              </p>
            </motion.p>
          </div>
          <motion.p
            initial={{ x: -100, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                duration: 2,
              },
            }}
            transition={{ duration: 1000 }}
          >
            <h1 className="space-y-2 fontfamily textshadow  text-xl pt-5  lg:text-4xl tracking-wide leading-loose text-white  font-semibold textshadow capitalize">
              <p> Live, Run, and Explore The Power</p>
            </h1>
          </motion.p>

          <motion.p
            initial={{ x: -100, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                duration: 2,
              },
            }}
            transition={{ duration: 1000 }}
          >
            <p className="lg:text-xl lg:pt-7 text-lg text-white">
              ATS batteries will keep you going until the end
            </p>
          </motion.p>
        </div>
      </div>

      <WhatSolution />
      <Mission />
      <Story />
      {/* <OurLeader /> */}
      <Faq />
      <ContactFooter />
      <Footer />
    </div>
  );
};

export default About;
