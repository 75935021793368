import React from "react";
import HeadingOnly from "../common/HeadingOnly";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const WhatSolution = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const textVariants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 3,
      },
    },
  };

  const data = [
    {
      id: 1,
      img: "/assets/icons/sol1.png",
      title: "Long-term investment",
      des: "We make batteries accessible for everyone, with higher qualities and low maintenance costs.",
    },
    {
      id: 2,
      img: "/assets/icons/sol2.png",
      title: "Professional Team ",
      des: " Our team is full of professionals to guide and assist you throughout the purchase and installation.",
    },
    {
      id: 3,
      img: "/assets/icons/sol5.png",
      title: "Long Battery Life",
      des: "The batteries have a long lifespan with minimal maintenance requirements.",
    },
    {
      id: 4,
      img: "/assets/icons/sol4.png",
      title: "Customer Support",
      des: "We provide pre and post-customer service. Give us a ring at ",
      number : '+91-9650205622.'
    },
  ];
  return (
    <div className=" bg-[#F1F1F1]">
      <div className="container-ats grid lg:grid-cols-2 gap-8 items-center py-24 lg:px-0 px-5">
        <motion.p
          ref={ref}
          animate={controls}
          variants={textVariants}
          initial={{ y: -50, opacity: 0 }}
          transition={{ duration: 2000 }}
        >
          {" "}
          <div className="space-y-4">
            <HeadingOnly title="EXPERIENCE UNINTERRUPTED ENERGY CHANGE" />
            <p className="text-base font-medium">
            Change it to, We've Got You The Best Energy Support
            </p>
            <p>
              {" "}
              The need for electrical supply is never ending as the world is running on currents. We are ATS Batteries, which is dedicated to supplying the demand with electrical power while causing the least harm to the environment.
            </p>
          </div>
        </motion.p>

        <div className="grid lg:grid-cols-2 gap-12">
          {data.map((gf, xgf) => (
            <motion.p
              ref={ref}
              animate={controls}
              variants={textVariants}
              initial={{ y: -60, opacity: 0 }}
              transition={{ duration: 2000 }}
            >
              {" "}
              <div
                className="flex lg:justify-center items-center gap-4"
                key={xgf}
              >
                <img src={gf.img} className="w-12 h-12" />
                <div className="space-y-2">
                  <h2 className="text-lg font-bold">{gf.title}</h2>
                  <p className="text-sm">{gf.des}<span className="font-bold">{gf?.number}</span></p>
                </div>
              </div>
            </motion.p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatSolution;
