import React from "react";
import ContactFooter from "../components/common/ContactFooter";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import Contactbanner from "../components/contact/Contactbanner";
// import ContactFooters from '../components/contact/ContactFooters';
import Contactheading from "../components/contact/Contactheading";
import Banner from "../components/home/Banner";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <div>
      <Navbar />

      <Banner
        img="/assets/images/aboutbg.png"
        pageTitle="GET IN TOUCH WITH US"
        title="Change the way you get Your appliances Electrified"
        des=" Our ATS Batteries are your one-time solution to your power
        supply."
      />

      {/* <div
        className="w-full  bg-no-repeat   bg-center bg-cover rounded-sm h-[60vh] relative"
        style={{ backgroundImage: `url(${"/assets/images/aboutbg.png"})` }}
      >
        <div className="w-full flex justify-end flex-col absolute bottom-10 px-20">
          <div className="flex items-center gap-5">
            <div className="h-10 w-[0.15rem] bg-primary"></div>

            <motion.p
              initial={{ x: -100, opacity: 0 }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  duration: 2,
                },
              }}
              transition={{ duration: 1000 }}
            >
              <p className="text-sm  text-primary drop-shadow-2xl font-semibold tracking-widest">
                Get in touch us
              </p>
            </motion.p>
          </div>
          <motion.p
            initial={{ x: -100, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                duration: 2,
              },
            }}
            transition={{ duration: 1000 }}
          >
            <h1 className="space-y-2 fontfamily textshadow lg:w-[50%] text-xl pt-5  lg:text-4xl tracking-wide leading-loose text-white  font-semibold textshadow capitalize">
              <p> Change the way you get Your appliances Electrified</p>
            </h1>
          </motion.p>

          <motion.p
            initial={{ x: -100, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                duration: 2,
              },
            }}
            transition={{ duration: 1000 }}
          >
            <p className="lg:text-xl lg:pt-7 w-[50%] text-lg text-white">
              Our ATS Solar Batteries are your one-time solution to your power
              supply.
            </p>
          </motion.p>
        </div>
      </div> */}

      <Contactbanner />
      {/* <ContactFooters/> */}
      <Footer />
    </div>
  );
};

export default Contact;
