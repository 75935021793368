import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useInView } from "framer-motion";
import React, { useRef } from "react";

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "red" }}
//       onClick={onClick}
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "green" }}
//       onClick={onClick}
//     />
//   );
// }

const productData = [
  {
    id: 1,
    // pimage: "/assets/battery/e-rickshawTwo.png",
    pimage: "/assets/img/rickshaw.png",
    pname: "E-Rickshaw Batteries",
    desc: "With long power backups and reserves, they will help you ride your passengers around the city without any breaks. Our price-friendly batteries will update your tuk-tuks to E-rickshaws.    ",
    bgcolor: "group-hover:text-white",
    icon: <AiOutlineArrowRight />,
    link: "/e-rickshaw-battery",
  },
  // {
  //   id: 2,
  //   pimage: "assets/img/Car.png",
  //   pname: "Automotive Batteries",
  //   desc: "Find your vehicle's power boost with a quick recharge and a long power source. Our automotive batteries are compatible with every type of automobile .",
  //   bgcolor: "group-hover:text-white",
  //   icon: <AiOutlineArrowRight />,
  //   link: "/automotive-battery",
  // },
  {
    id: 3,
    pimage: "/assets/img/ats image2.png",
    pname: "Solar Flare Batteries",
    desc: "Update your household with solar power and ATS Batteries promises to deliver the best-in-class high-end solar batteries. smooth power supply and long power reserves.",
    bgcolor: "group-hover:text-white",
    icon: <AiOutlineArrowRight />,
    link: "/solar-flare-battery",
  },
  {
    id: 4,
    pimage: "/assets/img/tubular battery1.png",
    pname: "Powerful Tubular Batteries",
    desc: "With thick positive tubular plates, they have rigid power backups and fast recharge. Also, a larger electrolyte reserve volume ensures a once a year top-up requirement during service years.",
    bgcolor: "group-hover:text-white",
    icon: <AiOutlineArrowRight />,
    link: "/tublar-batteries",
  },
  {
    id: 5,
    pimage: "/assets/img/inverter 3.png",
    pname: "Inverter Batteries",
    desc: "ATS Batteries batteries are lightweight, fast-charging batteries that can power small and large electrical appliances. Find yourself the best version of inverter batteries with us.",
    bgcolor: "group-hover:text-white",
    icon: <AiOutlineArrowRight />,
    link: "/inverter-batteries",
  },
];

const OurProducts = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { threshold: 0.5 });
  const settings = {
    className: "center",
    slidesToShow: 3,
    focusOnSelect: true,
    dots: false,
    infinite: true,
    arrows: true,
    loop: true,
    autoplay: true,
    speed: 2000,

    lazyLoad: true,
    accessibility: true,
    cssEase: "ease-out",
    swipeToSlide: true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 4000,
        },
      },
    ],
  };
  return (
    <>
      <div className="py-20 space-y-8 proSlider">
        {/* <div className="px-12">
          <Heading
            title="ATS SOLAR PRODUCTS "
            description="Our range will brighten up your world, without any power brakes. We only offer advance batteries built on latest technologies.            "
          />
        </div> */}
        <div className="lg:flex justify-between container-ats ">
          <div className="md:px-0 px-6">
            <h2 className="text-4xl tracking-wider font-semibold uppercase text-[#0D0D0D]">
              ATS Batteries PRODUCTS
            </h2>
            <div
              className="w-24 h-[3px]  bg-primary mt-3"
              ref={ref}
              style={{
                transform: isInView ? "none" : "translateX(200px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              }}
            ></div>
          </div>

          <div className=" leading-relaxed text-black text-justify text-base  lg:py-0 py-4 md:px-0 px-6 ">
            Our range will brighten up your world, without any power brakes. We
            only offer advance batteries built on latest technologies.
          </div>
        </div>
        {productData.slice(2, 3).map((item) => (
          <>
            <div className="group py-12 px-4">
              <div className="relative border-ternary duration duration-1000 drop-shadow-md group-hover:bg-primary bg-[#F1F1F1] flex flex-row justify-between items-center rounded-xl py-8">
                <div className="flex justify-center items-center w-1/2">
                  <img
                    src={item.pimage}
                    alt="image"
                    className="mt-5 rounded-lg w-96 h-64"
                  />
                </div>
                <div className="w-1/2 px-8">
                  <h3
                    className={`${item.bgcolor} text-black text-2xl font-semibold pt-3`}
                  >
                    {item.pname}
                  </h3>
                  <p
                    className={`${item.bgcolor} text-black text-lg text-start pb-10`}
                  >
                    {item.desc}
                  </p>
                </div>
              </div>
            </div>
          </>
        ))}

        {/* <Slider {...settings} className="w-[90%] mx-auto">
          {productData.map((item) => (
            <>
              <div className="group py-8 px-4">
                <div className="relative h-96 border-ternary duration duration-1000  drop-shadow-md group-hover:bg-primary  bg-[#F1F1F1] flex flex-col justify-between items-start  rounded-xl">
                  <div className="flex justify-center items-center mx-auto">
                    <img
                      src={item.pimage}
                      alt="image"
                      className="mt-5  rounded-lg w-72 h-52 mx-auto"
                    />
                  </div>
                  <h3
                    className={`${item.bgcolor} text-black text-xl font-semibold pt-3 px-5`}
                  >
                    {item.pname}
                  </h3>

                  <p
                    className={`${item.bgcolor} text-black   text-start px-5 text-sm pb-10`}
                  >
                    {item.desc}
                  </p>

                  <div className=" bg-primary p-2 duration duration-1000 rounded-full group-hover:bg-black absolute -bottom-5 right-10"> */}
        {/* <AiOutlineArrowRight
                      size={24}
                      className="text-white group-hover:text-white  cursor-pointer hover:translate-x-2 duration-150 "
                    /> */}

        {/* <Link to={item.link}>
                      <div className="text-white group-hover:text-white  cursor-pointer hover:translate-x-2 duration-150 ">
                        {item.icon}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          ))}
        </Slider> */}
      </div>
    </>
  );
};

export default OurProducts;
