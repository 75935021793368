import React from "react";
import ContactFooter from "../components/common/ContactFooter";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import Banner from "../components/home/Banner";
import ApplicationsTublar from "../components/TublarBatteries/ApplicationsTublar";
import Batteries from "../components/TublarBatteries/Batteries";
import DimensionsTublar from "../components/TublarBatteries/DimesionsTublar";
import ModelTublar from "../components/TublarBatteries/ModelTublar";

const TublarBattries = () => {
  return (
    <>
      <Navbar />
      <Banner
        img="/assets/battery/banner-b.webp"
        pageTitle="Have Everlasting Power Supply  "
        title="ATS Tublar batteries can make
                The power supply go wild
                "
      />
      <Batteries />
      <ApplicationsTublar />
      <DimensionsTublar />
      <ModelTublar />

      <ContactFooter />
      <Footer />
    </>
  );
};

export default TublarBattries;
