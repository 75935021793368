import React from "react";
import { Link } from "react-router-dom";
import { ImFacebook } from "react-icons/im";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
const Footer = () => {
  return (
    <>
      <a
        href="https://api.whatsapp.com/send?phone=919650205622&text=Hello !!"
        className="float"
        target="_blank"
      >
        <BsWhatsapp className="my-float" />
      </a>
      <footer
        className="footerclass"
        // style={{ backgroundImage: "url('/assets/images/banner3.png')" }}
        style={{
          backgroundImage: "url('/assets/images/footerimg.png')",
        }}
      >
        <div className="flex justify-center items-center gap-4 w-full">
          <div className="inline-block text-primary p-3 rounded-full cursor-pointer bg-white z-10 hover:scale-105 hover:text-black duration-500">
          <a  href="https://www.facebook.com/atsbatteries"> <ImFacebook /> </a>  
          </div>
          <div className="inline-block text-primary p-3 rounded-full cursor-pointer  bg-white  z-10 hover:scale-105 hover:text-black duration-500">
          <a  href="https://www.instagram.com/atssolarbatteries/">  <BsInstagram /> </a>
          </div>
          {/* <div className="inline-block text-primary p-3 rounded-full cursor-pointer  bg-white  z-10 hover:scale-105 hover:text-black duration-500">
            <BsTwitter />
          </div> */}
          <div className="inline-block text-primary p-3 rounded-full cursor-pointer  bg-white  z-10 hover:scale-105 hover:text-black duration-500">
          <a  href="https://youtube.com/channel/UCe6Up6NLXLMcROf8rvnbRAQ">  <BsYoutube /> </a>  
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-4 w-full pt-5">
          <div className="flex  justify-center items-center gap-4 pl-5">
            <div className="text-sm text-white text-semibold textshadow pr-4  border-r-2 hover:scale-105 duration-500 z-10 cursor-pointer ">
              <Link to="/terms-conditions"> Terms of use </Link>
            </div>
            <div className="text-sm text-white text-semibold  pr-4   textshadow hover:scale-105 duration-500 z-10 cursor-pointer ">
              <Link to="/privacy-policy"> Privacy Policy</Link>
            </div>

            {/* <div className="text-sm text-white text-semibold  textshadow hover:scale-105 duration-500 z-10 cursor-pointer ">
                            <Link to="#">  Careers </Link>

                        </div> */}
          </div>

          <footer className="  bg-[#EA1D2480] text-center w-full text-sm  py-5 lg:mt-10 ">
            <span className="text-white drop-shadow-2xl">
              {" "}
              Copyright © 2022 All Rights Reserved - Varcas Industries Pvt. Ltd
            </span>
          </footer>
        </div>
      </footer>
    </>
  );
};

export default Footer;
