import React from "react";
import Heading from "../common/Heading";
import { MdElectricRickshaw } from "react-icons/md";

const RickshawApplication = () => {
  const data = [
    {
      id: 1,
      img: (
        <MdElectricRickshaw className="w-12 h-12 text-primary group-hover:text-white" />
      ),
      title: "E-Rickshaw Batteries",
      des: "E-Rickshaws can get anywhere and through any road, but they need a battery that can provide them with an interrupted power supply to run. The smoother the battery works, the better the E-Rickshaw runs. Our E-Rickshaw batteries are applicable for all types of E-Rickshaws and come with negligible maintenance requirements and higher performance.",
    },
  ];
  return (
    <div className="px-4 bg-secondary lg:space-y-16 space-y-8 py-12">
      <div className="container-ats">
        {" "}
        <Heading
          title="OUR APPLICATION"
          description="Our E-Rickshaw batteries are applicable for all types of E-Rickshaws and come with negligible maintenance requirements and higher performance."
        />
      </div>

      <div className="grid lg:grid-cols-2 container-ats  grid-cols-1  lg:px-10 px-0 mx-auto  lg:gap-4 gap-7">
        {data.map((e, obj) => (
          <>
            <div className="group rounded space-y-4 bg-white hover:bg-black hover:-translate-y-4 duration-500 p-8 shadow-md hover:shadow-xl">
              {e.img}
              <h2 className="group-hover:text-white font-semibold">
                {e.title}
              </h2>
              <p className="group-hover:text-white text-sm tracking-wide leading-loose">
                {e.des}
              </p>
            </div>

            <div>
              <img
                src="assets/battery/e-rickshawTwo.png"
                className="w-full h-full "
              />
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default RickshawApplication;
