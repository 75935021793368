import React from "react";
import Heading from "../common/Heading";
import { FaHospitalAlt, FaTrafficLight } from "react-icons/fa";
import { ImLab } from "react-icons/im";

const ApplicationInverter = () => {
  const data = [
    {
      id: 1,
      img: (
        <FaHospitalAlt className="w-12 h-12 text-primary group-hover:text-white" />
      ),
      title: "Hospitals",
      des: "The need for power doesn’t end with small electronic appliances like hospitals. There is mega and small equipment that requires never-ending power. ATS Batteries Inverter Batteries can deliver an infinite power supply.      ",
    },
    {
      id: 2,
      img: <ImLab className="w-12 h-12 text-primary group-hover:text-white" />,
      title: "Laboratories",
      des: "Is there even a machine in the laboratory that doesn’t need the power to run today? All the significant laboratories need a stable power supply to run their experiments, which is possible with ATS Batteries inverter batteries",
    },
    {
      id: 3,
      img: (
        <FaTrafficLight className="w-12 h-12 text-primary group-hover:text-white" />
      ),
      title: "Traffic Lights",
      des: "Yes, the inverter batteries are suitable for traffic lights and are compatible with all types of equipment and can easily handle sensitive appliances. These batteries provide an uninterrupted power supply with long reserves.      ",
    },
  ];
  return (
    <div className="px-4 bg-secondary lg:space-y-16 space-y-8 py-12">
      <div className="container-ats">
        <Heading
          title="OUR APPLICATION"
          description="The ATS Batteries inverter batteries are well suited for all the big and small areas of applications with their range of compatibility with electronic appliances."
        />
      </div>
      <div className="grid lg:grid-cols-3 container-ats   grid-cols-1  lg:px-5 mx-auto  lg:gap-4 gap-7">
        {data.map((e, obj) => (
          <div className="group rounded space-y-4 bg-white hover:bg-black hover:-translate-y-4 duration-500 p-8 shadow-md hover:shadow-xl">
            {e.img}
            <h2 className="group-hover:text-white font-semibold">{e.title}</h2>
            <p className="group-hover:text-white text-sm">{e.des}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplicationInverter;
