import React from "react";
import Navbar from "./common/Navbar";
import Footer from "./common/Footer";

const Privacypolicy = () => {
  return (
    <div>
      {" "}
      <>
        <Navbar />
        <div
          className=" w-full lg:h-screen h-[50vh] bg-no-repeat   bg-center bg-cover rounded-sm "
          style={{ backgroundImage: `url(${`/assets/images/aboutbg.png`})` }}
        >
          <div className="absolute lg:space-y-6 lg:left-16 left-6 lg:top-64 top-44">
            <div className="lg:w-[100%] w-full">
              <div className="flex items-center gap-5">
                <div className="h-10 w-[0.15rem] bg-primary"></div>
                <p className="text-sm  text-primary drop-shadow-2xl font-semibold tracking-widest">
                  GET IN TOUCH WITH US
                </p>
              </div>
              <h1 className="space-y-2 fontfamily textshadow  text-xl py-3 w-full lg:text-5xl text-white  font-semibold textshadow capitalize">
                <p>Privacy Policy</p>
              </h1>
            </div>
          </div>
        </div>
        <div className="lg:px-20 px-5 pt-10 pb-12">
          <h4 className="text-4xl py-3 font-semibold text-primary">
            Privacy Policies:
          </h4>

          <div>
            <div>
              <ul className="lg:px-10 px-5">
                <li className="list-disc text-justify py-2 leading-loose">
                  The data provided by you will remain confidential with us.
                </li>
                <li className="list-disc text-justify py-2 leading-loose">
                  We will only access your data to deliver promotional events
                  and updates.
                </li>
                <li className="list-disc text-justify py-1 leading-loose">
                  We will not make unnecessary phone calls on your phone number.
                </li>
                <li className="list-disc text-justify py-1 leading-loose">
                  You can unsubscribe to our promotional emails anytime.
                </li>

                <li className="list-disc text-justify py-1 leading-loose">
                  Our concerned person will contact you to solve the query, and
                  you won't be restricted from sharing any personal information
                  you don't wish to disclose.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <Footer />
      </>
    </div>
  );
};

export default Privacypolicy;
