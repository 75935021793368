import React from "react";
import ContactFooter from "../components/common/ContactFooter";
import { NavLink } from "react-router-dom";
import Footer from "../components/common/Footer";
import { BsArrowLeft } from "react-icons/bs";
import Navbar from "../components/common/Navbar";
import { BsArrowRight } from "react-icons/bs";
import Banner from "../components/home/Banner";

const BlogDetail4 = () => {
  return (
    <div>
      <Navbar />

      <Banner
        img="/assets/battery/banner-blog.png"
        pageTitle="GET IN TOUCH WITH US"
        title="Learn And Grow"
        des="Grab The Power Of Knowledge, With The Power Of ATS Batteries"
      />

      <div className="lg:px-32  px-5">
        <div className=" pt-10 relative">
          <NavLink to="/blog">
            <div className="flex gap-3 py-2">
              <BsArrowLeft className="pt-1 w-6 h-6" />
              <p className="font-semibold ">Back</p>
            </div>
          </NavLink>
          <img
            src="assets/battery/img-1.png"
            className="w-full  h-96 bg-black/80 bg-blend-overlay"
          />

          <div className="absolute lg:bottom-16 bottom-10 lg:px-12 px-5 bg-black py-6 bg-opacity-50 rounded">
            <div className="flex gap-5">
              <img src="assets/images/star.svg" />

              <p className="text-white">Top Story</p>
              <p className="text-white">|</p>
              <p className="text-white">4 min read</p>
            </div>

            <h4 className="text-white pt-3 lg:text-3xl font-bold   w-full">
            The Evolution of Tubular Batteries and Their Impact on Modern Energy Solutions
            </h4>

            <div className="flex gap-4">
              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/eye.svg" />
                <p className="text-white text-sm font-semibold">123k Views</p>
                <p className="text-white">|</p>
              </div>

              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/heart-icon.svg" />
                <p className="text-white text-sm font-semibold">10k Likes</p>
              </div>
            </div>
          </div>
        </div>
        <h3 className="font-semibold pt-4">
          By : Vanshika Khanna | 7th july 2022
        </h3>
        <p className="text-justify py-5 leading-loose">
          The quest for reliable energy storage solutions has been a driving force in technological
          advancement for decades. Tubular batteries have emerged as a critical component in this
          arena, offering unmatched reliability and efficiency. This blog explores the evolution of
          tubular batteries, their technological advancements, and their impact on modern energy
          solutions.

        </p>

        <h4 className="text-2xl font-medium">
          The Birth of Tubular Batteries.
        </h4>
        <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
        <p className="text-justify py-5 leading-loose">
          The development of tubular batteries dates back to the early 20th century. Originally
          designed for industrial applications, these batteries quickly gained popularity due to their
          robust construction and ability to endure deep discharges. The initial designs were bulky and
          expensive, but they paved the way for significant innovations in battery technology.
        </p>

        <h4 className="text-2xl font-medium">
          Technological Advancements
        </h4>
        <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
        <p className="text-justify  leading-loose">
          Over the years, the technology behind tubular batteries has evolved dramatically.
          Innovations in materials, design, and manufacturing processes have enhanced their
          performance and reduced costs. Modern tubular batteries now feature high conductive
          materials, improved grid designs, and advanced separators, which collectively boost their
          efficiency and lifespan.
        </p>
        <ol className="list-decimal pl-5 py-5">
          <li>
            <strong>High Conductive Materials:</strong> Modern tubular batteries use lead alloy grids with
            additives like calcium and tin, enhancing conductivity and reducing corrosion.
          </li>
          <li>
            <strong>Improved Grid Design:</strong> The tubular design allows for a greater surface area,
            facilitating better electrolyte interaction and improving overall performance.
          </li>
          <li>
            <strong>Advanced Separators:</strong> Innovations in separator technology reduce the risk of short
            circuits and enhance the longevity of the battery.
          </li>
        </ol>


        <h4 className="text-2xl font-medium">
          Applications of Tubular Batteries
        </h4>
        <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
        <p className="text-justify  leading-loose">
          The versatility of tubular batteries makes them suitable for a wide range of applications.
          Their ability to provide consistent power over long periods has made them a preferred choice
          in various sectors.
        </p>
        <ol className="list-decimal pl-5 py-5">
          <li>
            <strong>Inverters:</strong> Tubular batteries are ideal for inverter systems, providing reliable backup
            power during outages.
          </li>
          <li>
            <strong>Power Plants:</strong> Their long service life and low maintenance requirements make them
            suitable for large-scale energy storage in power plants.
          </li>
          <li>
            <strong> Fire & Security Systems:</strong>Tubular batteries offer dependable power for critical
            systems like fire alarms and security setups, ensuring uninterrupted operation during
            emergencies.
          </li>
        </ol>

        <h4 className="text-2xl font-medium">
          Benefits of Tubular Batteries
        </h4>
        <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
        <p className="text-justify  leading-loose">
          Tubular batteries offer several advantages over other types of batteries, making them a
          popular choice for both residential and industrial applications.
        </p>
        <ol className="list-decimal pl-5 py-5">
          <li>
            <strong>Longevity:</strong> Tubular batteries have a longer service life compared to flat plate
            batteries, thanks to their robust construction and resistance to deep discharges.
          </li>
          <li>
            <strong>Low Maintenance:</strong> These batteries require minimal maintenance, reducing the
            overall cost of ownership.
          </li>
          <li>
            <strong>High Efficiency:</strong> The high conductive materials and improved design contribute to
            better efficiency and higher energy output.
          </li>
          <li>
            <strong>Durability:</strong>Tubular batteries are designed to withstand extreme temperatures and
            harsh environmental conditions.
          </li>
        </ol>

        <h4 className="text-2xl font-medium">
          Future of Tubular Batteries
        </h4>
        <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
        <p className="text-justify  leading-loose">
          The future of tubular batteries looks promising, with ongoing research and development
          aimed at further improving their performance and reducing costs. Advancements in materials
          science and manufacturing techniques are expected to lead to more efficient, cost-effective,
          and environmentally friendly tubular batteries.
        </p>
        <ol className="list-decimal pl-5 py-5">
          <li>
            <strong>Sustainable Materials:</strong> : Research is focused on developing eco-friendly materials
            that reduce the environmental impact of battery production and disposal.
          </li>
          <li>
            <strong>Enhanced Performance:</strong> Ongoing innovations aim to improve the energy density
            and cycle life of tubular batteries, making them even more competitive.
          </li>
          <li>
            <strong>Cost Reduction: </strong>: Advances in manufacturing processes are expected to lower
            production costs, making tubular batteries more accessible to a broader market.
          </li>
        </ol>

        <h4 className="text-2xl font-medium">
          Conclusion
        </h4>
        <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
        <p className="text-justify  leading-loose">
          Tubular batteries have come a long way since their inception, evolving into a reliable and
          efficient energy storage solution. Their technological advancements and wide range of
          applications make them indispensable in modern energy solutions. As research continues to
          push the boundaries of battery technology, tubular batteries are poised to play an even more
          significant role in the future of energy storage.
        </p>
      </div>

      <div className="lg:px-32 px-5 pb-20 pt-5">
        {/* <div className="lg:grid grid-cols-2 gap-10">
          <div>
            <img
              src="assets/battery/blog-set1.jpg"
              className="h-80 w-full rounded"
            />
          </div>
          <div className="lg:pt-0 pt-8">
            <img
              src="assets/battery/blog-set2.png"
              className="h-80 w-full rounded"
            />
          </div>
        </div> */}
      </div>

      <ContactFooter />
      <Footer />
    </div>
  );
};

export default BlogDetail4;
