import React from "react";
import ContactFooter from "../components/common/ContactFooter";
import { NavLink } from "react-router-dom";
import Footer from "../components/common/Footer";
import { BsArrowLeft } from "react-icons/bs";
import Navbar from "../components/common/Navbar";
import { BsArrowRight } from "react-icons/bs";
import Banner from "../components/home/Banner";

const BlogDetail5 = () => {
    return (
        <div>
            <Navbar />

            <Banner
                img="/assets/battery/banner-blog.png"
                pageTitle="GET IN TOUCH WITH US"
                title="Learn And Grow"
                des="Grab The Power Of Knowledge, With The Power Of ATS Batteries"
            />

            <div className="lg:px-32  px-5">
                <div className=" pt-10 relative">
                    <NavLink to="/blog">
                        <div className="flex gap-3 py-2">
                            <BsArrowLeft className="pt-1 w-6 h-6" />
                            <p className="font-semibold ">Back</p>
                        </div>
                    </NavLink>
                    <img
                        src="assets/battery/img-1.png"
                        className="w-full  h-96 bg-black/80 bg-blend-overlay"
                    />

                    <div className="absolute lg:bottom-16 bottom-10 lg:px-12 px-5 bg-black py-6 bg-opacity-50 rounded">
                        <div className="flex gap-5">
                            <img src="assets/images/star.svg" />

                            <p className="text-white">Top Story</p>
                            <p className="text-white">|</p>
                            <p className="text-white">4 min read</p>
                        </div>

                        <h4 className="text-white pt-3 lg:text-3xl font-bold   w-full">
                            Installing and Maintaining Tubular Batteries: Best Practices for
                            Longevity and Efficiency
                        </h4>

                        <div className="flex gap-4">
                            <div className="flex gap-2 pt-3 items-center justify-center">
                                <img src="assets/images/eye.svg" />
                                <p className="text-white text-sm font-semibold">123k Views</p>
                                <p className="text-white">|</p>
                            </div>

                            <div className="flex gap-2 pt-3 items-center justify-center">
                                <img src="assets/images/heart-icon.svg" />
                                <p className="text-white text-sm font-semibold">10k Likes</p>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className="font-semibold pt-4">
                    By : Vanshika Khanna | 7th july 2022
                </h3>
                <p className="text-justify py-5 leading-loose">
                    Tubular batteries are renowned for their durability and long service life, making them a
                    popular choice for various applications. Proper installation and maintenance are crucial to
                    maximizing their performance and ensuring longevity. This blog outlines best practices for
                    installing and maintaining tubular batteries to optimize efficiency and reliability.
                </p>

                <h4 className="text-2xl font-medium">
                    Installing Tubular Batteries: Step-by-Step Guide
                </h4>
                <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
                <p className="text-justify  leading-loose">
                    Proper installation is the foundation for ensuring tubular batteries operate at peak efficiency.
                    Follow these steps:
                </p>
                <ol className="list-decimal pl-5 py-5">
                    <li>
                        <strong>Location Selection:</strong>: Choose a well-ventilated area away from direct sunlight and
                        extreme temperatures.
                    </li>
                    <li>
                        <strong>Mounting:</strong>Secure the batteries on a stable, level surface to prevent vibration and
                        potential damage.
                    </li>
                    <li>
                        <strong>Connections:</strong>Connect the batteries in a series or parallel configuration as per your
                        system requirements, ensuring proper polarity
                    </li>
                    <li>
                        <strong>Ventilation:</strong> Ensure adequate ventilation to dissipate heat generated during
                        operation.
                    </li>
                    <li>
                        <strong>Safety Precautions:</strong>: Wear protective gear when handling batteries to prevent acid
                        exposure and electrical hazards.
                    </li>
                </ol>


                <h4 className="text-2xl font-medium">
                    Maintaining Tubular Batteries: Essential Tips
                </h4>
                <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
                <p className="text-justify  leading-loose">
                    Regular maintenance helps extend the lifespan and optimize performance of tubular
                    batteries. Follow these tips:
                </p>
                <ol className="list-decimal pl-5 py-5">
                    <li>
                        <strong>Monitoring Electrolyte Levels:</strong>Check electrolyte levels regularly and top up with
                        distilled water as needed, maintaining levels above the minimum mark.
                    </li>
                    <li>
                        <strong>Cleaning Terminals:</strong> Clean battery terminals and connections periodically to prevent
                        corrosion, using a mixture of baking soda and water.
                    </li>
                    <li>
                        <strong> Temperature Control:</strong>Maintain ambient temperatures within recommended ranges
                        to prevent overheating or freezing, which can degrade battery performance.
                    </li>
                    <li>
                        <strong>Equalizing Charge:</strong>: Perform equalization charging periodically to balance cell
                        voltages and prevent stratification, improving overall battery performance.
                    </li>
                    <li>
                        <strong> Safety Inspections:</strong>: Inspect batteries for signs of damage, leaks, or bulging
                        regularly, addressing issues promptly to avoid further damage or safety hazards.

                    </li>
                </ol>

                <h4 className="text-2xl font-medium">
                    Maximizing Efficiency with Tubular Batteries
                </h4>
                <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
                <p className="text-justify  leading-loose">
                    Optimizing the efficiency of tubular batteries involves implementing strategies to minimize
                    energy loss and maximize storage capacity:
                </p>
                <ol className="list-decimal pl-5 py-5">
                    <li>
                        <strong> Proper Sizing:</strong>: Ensure your battery bank is appropriately sized to meet your power
                        demands without overloading or underutilizing the batteries.
                    </li>
                    <li>
                        <strong>Charge Controllers:</strong> Use efficient charge controllers to regulate charging currents
                        and prevent overcharging, which can reduce battery life.
                    </li>
                    <li>
                        <strong>Optimized Charging:</strong> Charge batteries during off-peak hours to reduce electricity
                        costs and minimize grid dependency.
                    </li>
                    <li>
                        <strong>Discharge Depth:</strong>: Avoid excessive discharges below recommended levels to
                        prolong battery life and maintain performance.
                    </li>
                </ol>

                <h4 className="text-2xl font-medium">
                    Troubleshooting Common Issues
                </h4>
                <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
                <p className="text-justify  leading-loose">
                    Address common issues promptly to prevent damage and maintain battery efficiency:
                </p>
                <ol className="list-decimal pl-5 py-5">
                    <li>
                        <strong>Low Voltage:</strong> Check for loose connections or faulty terminals and rectify them to
                        restore proper voltage levels.
                    </li>
                    <li>
                        <strong>Overheating:</strong> Improve ventilation or relocate batteries to a cooler environment if
                        overheating occurs frequently.
                    </li>
                    <li>
                        <strong>Corrosion: </strong>Clean terminals and apply terminal protectors to prevent corrosion
                        buildup and ensure reliable electrical connections.
                    </li>
                </ol>

                <h4 className="text-2xl font-medium">
                    Conclusion
                </h4>
                <div className="w-[80%] h-[2px] bg-primary mt-3"></div>
                <p className="text-justify  leading-loose">
                    Installing and maintaining tubular batteries according to best practices is essential for
                    maximizing their lifespan, efficiency, and reliability. By following proper installation
                    procedures, conducting regular maintenance, and optimizing operational strategies, you can
                    ensure your tubular batteries provide consistent and dependable power for years to come.

                </p>
            </div>

            <div className="lg:px-32 px-5 pb-20 pt-5">
                {/* <div className="lg:grid grid-cols-2 gap-10">
                    <div>
                        <img
                            src="assets/battery/blog-set1.jpg"
                            className="h-80 w-full rounded"
                        />
                    </div>
                    <div className="lg:pt-0 pt-8">
                        <img
                            src="assets/battery/blog-set2.png"
                            className="h-80 w-full rounded"
                        />
                    </div>
                </div> */}
            </div>

            <ContactFooter />
            <Footer />
        </div>
    );
};

export default BlogDetail5;
