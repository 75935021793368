import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const contactSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter your name"),
  email: Yup.string().email().required("Please enter your email"),
  company: Yup.string().required("Company is required"),
  country: Yup.string().required("Country is required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("A phone number is required"),
  message: Yup.string().min(10).required("Please enter your message"),
});

