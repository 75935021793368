import React from "react";
import { BsArrowRight } from "react-icons/bs";
import Aboutbg from "../../img/aboutbg.png";
import { motion } from "framer-motion";

const Banner = ({ img, pageTitle, title, des }) => {
  return (
    <div
      className="w-full  bg-no-repeat bg-black/40 bg-blend-overlay  bg-center bg-cover rounded-sm h-[60vh] relative mt-[80px]  "
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="w-full flex justify-end flex-col absolute lg:bottom-20 bottom-36 lg:px-20 px-5">
        <div className="flex items-center gap-5">
          <div className="h-10 w-[0.15rem] bg-primary"></div>

          <motion.p
            initial={{ x: -100, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                duration: 2,
              },
            }}
            transition={{ duration: 1000 }}
          >
            <p className="text-sm  text-primary text-shadow2 font-semibold tracking-widest">
              {pageTitle}
            </p>
          </motion.p>
        </div>
        <motion.p
          initial={{ x: -100, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: {
              duration: 2,
            },
          }}
          transition={{ duration: 1000 }}
        >
          <h1 className="space-y-2 fontfamily textshadow lg:w-[50%] w-full text-2xl pt-5  lg:text-4xl tracking-wide leading-loose text-white  font-semibold textshadow capitalize">
            <p> {title}</p>
          </h1>
        </motion.p>

        <motion.p
          initial={{ x: -100, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: {
              duration: 2,
            },
          }}
          transition={{ duration: 1000 }}
        >
          <p className="lg:text-xl lg:pt-7 pt-2 lg:w-[50%] w-full  text-lg text-white">
            {" "}
            {des}
          </p>
        </motion.p>
      </div>
    </div>
  );
};

export default Banner;
