import React from "react";
import ContactFooter from "../components/common/ContactFooter";
import { NavLink } from "react-router-dom";
import Footer from "../components/common/Footer";
import { BsArrowLeft } from "react-icons/bs";
import Navbar from "../components/common/Navbar";
import { BsArrowRight } from "react-icons/bs";
import Banner from "../components/home/Banner";

const BlogDetai2 = () => {
  return (
    <div>
      <Navbar />

      <Banner
        img="/assets/battery/banner-blog.png"
        pageTitle="GET IN TOUCH WITH US"
        title="Learn And Grow"
        des="Grab The Power Of Knowledge, With The Power Of ATS Solar"
      />

      <div className="lg:px-32  px-5">
        <div className=" pt-10 relative">
          <NavLink to="/blog">
            <div className="flex gap-3 py-2">
              <BsArrowLeft className="pt-1 w-6 h-6" />
              <p className="font-semibold ">Back</p>
            </div>
          </NavLink>
          <img
            src="assets/battery/img-2.png"
            className="w-full  h-96 bg-black/80 bg-blend-overlay"
          />

          <div className="absolute lg:bottom-16 bottom-10 lg:px-12 px-5 bg-black py-6 bg-opacity-50 rounded">
            <div className="flex gap-5">
              <img src="assets/images/star.svg" className="" />

              <p className="text-white">Top Story</p>
              <p className="text-white">|</p>
              <p className="text-white">4 min read</p>
            </div>

            <h4 className="text-white pt-3 lg:text-3xl font-bold   w-full">
              How to Take Care of Your Inverter Battery
            </h4>

            <div className="flex gap-4">
              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/eye.svg" />
                <p className="text-white text-sm font-semibold">123k Views</p>
                <p className="text-white">|</p>
              </div>

              <div className="flex gap-2 pt-3 items-center justify-center">
                <img src="assets/images/heart-icon.svg" />
                <p className="text-white text-sm font-semibold">10k Likes</p>
              </div>
            </div>
          </div>
        </div>
        <h6 className="font-semibold pt-4">
          By : Sonika Khanna | 9th july 2022
        </h6>
        <p className="text-justify py-5 leading-loose">
          The batteries are the soul of the inverter as they are responsible for
          functioning and fulfilling the core task. Therefore, routine
          maintenance and upkeep are very important for the battery’s
          performance. A battery needs timely maintenance for uninterrupted and
          smooth operation and long life. They form an integral part of helping
          you live comfortably during the problematic and unbearable times of
          power cuts.
        </p>

        <h4 className="text-2xl py-3 font-medium">
          Tips to keep up your battery
        </h4>
        <div className="w-[80%] h-[2px] bg-primary"></div>
        <ul className="pl-8">
          <li className="list-disc text-justify py-2 leading-loose">
            Ventilation for the battery is very important as they get heated up
            during the charging operation and in case of lack of ventilation the
            tubes inside may expand and blast. Therefore, always use a
            <span className="font-bold">
              {" "}
              well-ventilated area for inverter installation.{" "}
            </span>
          </li>

          <li className="list-disc text-justify py-2 leading-loose">
            The more you use your battery the better it keeps up with regular
            and unregular operations. If the power cut doesn’t happen, then
            discharge the battery once a month and then recharge it.
          </li>
          <li className="list-disc text-justify py-2 leading-loose">
            <span className="font-bold">
              {" "}
              Check the water level of the battery,{" "}
            </span>
            and ensure that the water level is maintained between the minimum
            and maximum water limits. Complete regular top ups with distilled
            water
          </li>
        </ul>

        <h4 className="text-2xl py-3 font-medium y">
          Never use the rain water/tap water as it will ruin your battery life.
        </h4>

        <div className="w-[80%] h-[2px] bg-primary"></div>
        <ul className="pl-8">
          <li className="list-disc text-justify pt-3 pb-2 leading-loose">
            Always
            <span className="font-bold">
              {" "}
              keep the surface and sides of the battery clean{" "}
            </span>
            and dust-free.
          </li>

          <li className="list-disc text-justify py-2 leading-loose">
            <span className="font-bold">
              {" "}
              Keep the battery terminals corrosion-free{" "}
            </span>
            and rust-free. The restricted flow of current results in slow
            battery charging, which ultimately reduces battery life.
          </li>
          <li className="list-disc text-justify py-2 leading-loose">
            Keep
            <span className="font-bold">
              {" "}
              a check on the blocked vents of the battery{" "}
            </span>
            as they lead to hydrogen gas accumulation, which may lead to the
            bursting of the battery.
          </li>

          <li className="list-disc text-justify py-2 leading-loose">
            Install
            <span className="font-bold">
              {" "}
              the inverter away from the reach of the children, as it can be
              hazardous for them.{" "}
            </span>
          </li>

          <li className="list-disc text-justify py-2 leading-loose">
            <span className="font-bold">
              {" "}
              Conduct regular battery inspections and if the battery is dead or
              damaged, quickly do the needful with the help of a technician.
            </span>
          </li>
        </ul>

        <h4 className="text-2xl py-5 font-medium">
          See why inverter batteries fail earlier than their death date?
        </h4>
        <div className="w-[80%] h-[2px] bg-primary"></div>
        <ul className="pl-8">
          <li className="list-disc text-justify pt-3 pb-2 leading-loose">
            Poor quality of the battery charger.
          </li>

          <li className="list-disc text-justify py-2 leading-loose">
            Overcharging battery
          </li>
          <li className="list-disc text-justify py-2 leading-loose">
            Excess usage
          </li>

          <li className="list-disc text-justify py-2 leading-loose">
            Deep-discharge battery
          </li>

          <li className="list-disc text-justify py-2 leading-loose">
            Lack of regular maintenance
          </li>
        </ul>

        <p className="text-justify py-5 leading-loose">
          ATS Batteries batteries require less maintenance and offer rigid power
          support with an endless supply. During their service year, our tubular
          requires only once-a-year top-ups and comes with full customer support
          from our team.
        </p>
      </div>

      <div className="lg:px-32 px-5 pb-20 pt-5">
        <div className="lg:grid grid-cols-2 gap-10">
          <div>
            <img
              src="assets/battery/blog-set4.jpg"
              className="h-80 w-full rounded"
            />
          </div>
          <div className="lg:pt-0 pt-8">
            <img
              src="assets/battery/blog-set8.jpg"
              className="h-80 w-full rounded"
            />
          </div>
        </div>
      </div>

      <ContactFooter />
      <Footer />
    </div>
  );
};

export default BlogDetai2;
