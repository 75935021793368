import React from "react";
import { BsFillPlusCircleFill, BsFillMinusCircleFill } from "react-icons/bs";
import { AiFillMinusCircle } from "react-icons/ai";

const FaqOne = ({ title, des, desarray }) => {
  return (
    <>
      <button className="lg:w-96  text-left group focus:outline-none space-y-2 p-1 ">
        <div className="flex pl-2 gap-2 justify-start rounded-sm text-black border-1 border-white py-4  hover:bg-black hover:text-white font-semibold lg:text-base text-sm">
          <div className="">
            <BsFillPlusCircleFill className="h-5 w-5 group-focus:hidden" />
            <AiFillMinusCircle className="h-5 w-5 group-focus:flex hidden text-primary " />
          </div>
          <div>{title}</div>
        </div>

        <div className="hidden p-4  group-focus:block bg-gray-100 drop-shadow-2zl">
          {des ? (
            <p className="text-sm ">{des}</p>
          ) : (
            desarray.map((item) => (
              <ul className="px-12">
                <li className="list-disc ">{item}</li>
              </ul>
            ))
          )}
        </div>
      </button>
    </>
  );
};

export default FaqOne;
