import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { BiChevronUpCircle } from "react-icons/bi";
import { BsChevronUp } from "react-icons/bs";
// import { ChevronUpIcon } from "@heroicons/react/solid";
const Navbar = ({ bgcolor }) => {
  // setting mobile nav
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  // close menu on click
  const closeMenu = () => setClick(false);

  // change nav color when scrolling

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  return (
    <div
      className={
        color || !bgcolor
          ? "header bg-black  z-50 spacingYtext rajez spacingYlogo"
          : "header  bg-transparent  bg-opacity-80 rajez"
      }
    >
      <nav className="navbar font-nav">
        <Link to="/" className="logo flex items-center gap-2">
          <img
            // src="/assets/images/solar-01.png"
            src="/assets/images/atslogo.png"
            alt="logo"
            className="cursor-pointer w-40"
          />
          {/* <img
            src="/assets/images/power-01.png"
            alt="logo"
            className="cursor-pointer w-40"
          /> */}
        </Link>

        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="lg:flex hidden nav-item text-sm uppercase font-bold nav-desk  text-white hover:text-primary group relative cursor-pointer">
            <Link to="/" onClick={closeMenu}>
              HOME
            </Link>
          </li>

          <li className="hidden lg:flex nav-desk  group relative nav-item text-sm font-bold uppercase hover:text-primary  group dropdown  px-4 text-white cursor-pointer tracking-wide">
            <Link to="/about" onClick={closeMenu}>
              ABOUT US
            </Link>
          </li>

          <li className="nav-item text-sm uppercase  relative nav-desk group  text-white font-bold hover:text-primary">
            <Link to="#" className="hidden lg:block " onClick={closeMenu}>
              PRODUCTS
            </Link>

            <div className="hidden mt-3 top-10 -left-10 group-hover:flex group-hover:flex-col group absolute bg-black text-white duration-500 transition-all max-w-max  rounded-xl w-[250px]">
              {/* <Link to="/solar-flare-battery" className="w-full">
                <div className=" textclass font-semibold px-5 py-2  hover:text-white hover:bg-primary duration duration-700 text-start  m-2 rounded-xl  ">
                  Solar Flare Batteries
                </div>
              </Link> */}
              <Link to="/powerful-tublar-batteries" className="w-full">
                <div className=" textclass font-semibold px-5 py-2  hover:text-white hover:bg-primary  text-start  m-2 rounded-xl  duration duration-700">
                  Powerful Tubular Batteries
                </div>
              </Link>
              {/* <Link to="/inverter-batteries" className="w-full">
                <div className=" textclass font-semibold px-5 py-2  hover:text-white hover:bg-primary  text-start  m-2 rounded-xl duration duration-700">
                  Inverter Batteries
                </div>
              </Link> */}
              {/* <Link to="/e-rickshaw-battery" className="w-full">
                <div className=" textclass font-semibold px-5 py-2  hover:text-white hover:bg-primary  text-start m-2 rounded-xl  duration duration-700">
                  E-Rickshaw Batteries
                </div>
              </Link> */}
              {/* <Link to="/automotive-battery" className="w-full">
                <div className=" textclass font-semibold px-5 py-2  hover:text-white hover:bg-primary text-start m-2 rounded-xl  duration-500">
                  Automotive Batteries
                </div>
              </Link> */}
            </div>
          </li>

          <div className=" lg:hidden flex  nav-font ">
            <div className="mx-auto w-full rounded-2xl  ">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className=" flex w-full justify-between   rounded-lg   text-center  font-medium text-white   focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <li className=" px-4 text-base uppercase  py-1 text-white font-semibold hover:text-primary">
                        <Link to="/" onClick={closeMenu}>
                          Home
                        </Link>
                      </li>
                    </Disclosure.Button>

                    <Disclosure.Button className=" flex w-full justify-between   rounded-lg   py-2 text-center  font-medium text-white   focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <li className="nav-item text-sm uppercase    text-white font-semibold hover:text-primary">
                        <Link to="/about" onClick={closeMenu}>
                          About Us
                        </Link>
                      </li>
                    </Disclosure.Button>

                    <Disclosure.Button className=" flex w-full justify-between  rounded-lg px-4  py-2 text-center  font-medium text-white   focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span className="uppercase font-semibold">Products</span>

                      <BsChevronUp
                        className={`${
                          open ? "rotate-180 transform text-white" : ""
                        } h-5 w-5 `}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="  pb-2 px-5 text-gray-500">
                      {/* <Link to="/solar-flare-battery" className="w-full">
                        <div className="  font-semibold py-3 hover:text-secondary text-white max-w-max hover:bg-primary m-2 rounded">
                          Solar Flare Batteries
                        </div>
                      </Link> */}
                      <Link to="/powerful-tublar-batteries" className="w-full">
                        <div className="  font-semibold py-3 hover:text-secondary text-white max-w-max hover:bg-primary m-2 rounded">
                          Powerful Tubular Batteries
                        </div>
                      </Link>
                      {/* <Link to="/inverter-batteries" className="w-full">
                        <div className="  font-semibold py-3 hover:text-secondary text-white max-w-max hover:bg-primary m-2 rounded">
                          Inverter Batteries
                        </div>
                      </Link> */}
                      {/* <Link to="/e-rickshaw-battery" className="w-full">
                        <div className="  font-semibold py-3 hover:text-secondary text-white max-w-max hover:bg-primary m-2 rounded">
                          E-Rickshaw Batteries
                        </div>
                      </Link> */}

                      {/* <Link to="/automotive-battery" className="w-full">
                        <div className="  font-semibold py-3 hover:text-secondary text-white max-w-max hover:bg-primary m-2 rounded">
                          Automotive Batteries
                        </div>
                      </Link> */}
                    </Disclosure.Panel>

                    <Disclosure.Button className=" flex w-full justify-between   rounded-lg   py-2 text-center  font-medium text-white   focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <li className="nav-item text-sm uppercase  nav-desk   text-white font-semibold hover:text-primary">
                        <Link to="/blog" onClick={closeMenu}>
                          BLOGS
                        </Link>
                      </li>
                    </Disclosure.Button>

                    {/* <Disclosure.Button className=" flex w-full justify-between   rounded-lg   py-2 text-center  font-medium text-white   focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <li className=" text-sm uppercase px-4   text-white font-semibold hover:text-primary ">
                        <a href="/assets/images/ATS_Catalog_SpecSheet.compressed.pdf">
                          Catalogue
                        </a>
                      </li>
                    </Disclosure.Button> */}

                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className=" flex w-full justify-between  rounded-lg px-4  py-2 text-center  font-medium text-white   focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                            <span className="uppercase font-semibold">
                              Catalogue
                            </span>

                            <BsChevronUp
                              className={`${
                                open ? "rotate-180 transform text-white" : ""
                              } h-5 w-5 `}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="  pb-2 px-5 text-gray-500">
                            <a
                              href="/assets/images/ATS_Brochure_@C10.pdf"
                              className="w-full"
                            >
                              {/* <div className="  font-semibold py-3 hover:text-secondary text-white max-w-max hover:bg-primary m-2 rounded">
                                Solar Flare Batteries
                              </div> */}
                            </a>

                            <a
                              href="/assets/images/ATS_Brochure_INVERTER_2.0.pdf"
                              className="w-full"
                            >
                              <div className="  font-semibold py-3 hover:text-secondary text-white max-w-max hover:bg-primary m-2 rounded">
                                Inverter Batteries
                              </div>
                            </a>
                            <a
                              href="/assets/images/E-Rickshaw_pdf.pdf"
                              className="w-full"
                            >
                              <div className="  font-semibold py-3 hover:text-secondary text-white max-w-max hover:bg-primary m-2 rounded">
                                E-Rickshaw Batteries
                              </div>
                            </a>
                            <a
                              href="/assets/images/ATS_Catalog_SpecSheet.compressed.pdf"
                              className="w-full"
                            >
                              <div className="  font-semibold py-3 hover:text-secondary text-white max-w-max hover:bg-primary m-2 rounded">
                                Powerful Tubular Batteries
                              </div>
                            </a>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>

                    <Disclosure.Button className=" flex w-full justify-between   rounded-lg   py-2 text-center  font-medium text-white   focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <li className="nav-item text-sm uppercase  nav-desk   text-white font-semibold hover:text-primary">
                        <Link to="/contact" onClick={closeMenu}>
                          Contact
                        </Link>
                      </li>
                    </Disclosure.Button>
                  </>
                )}
              </Disclosure>
            </div>
          </div>

          {/* <li className="nav-item text-sm uppercase  nav-desk   text-white font-semibold hover:text-primary">
            <Link to="#" onClick={closeMenu}>
            CAREERS
            </Link>
          </li> */}
          <li className="nav-item text-sm uppercase hidden lg:block nav-desk   text-white font-semibold hover:text-primary">
            <Link to="/blog" onClick={closeMenu}>
              BLOGS
            </Link>
          </li>
          {/* <li className="nav-item text-sm uppercase lg:flex hidden nav-desk   text-white font-semibold hover:text-primary ">
            <a
              href="/assets/images/ATS_Catalog_SpecSheet.compressed.pdf"
              onClick={closeMenu}
            >
              Catalogue
            </a>
          </li> */}
          {/* <li className="nav-item text-sm uppercase  relative nav-desk group  text-white font-bold hover:text-primary"> */}
            {/* <Link to="#" className="hidden lg:block " onClick={closeMenu}>
              Catalogue
            </Link> */}

            {/* <div className="hidden mt-3 top-10 -left-10 group-hover:flex group-hover:flex-col group absolute bg-black text-white duration-500 transition-all max-w-max  rounded-xl w-[250px]"> */}
              {/* <a href="/assets/images/ATS_Brochure_@C10.pdf" className="w-full">
                <div className=" textclass font-semibold px-5 py-2  hover:text-white hover:bg-primary duration duration-700 text-start  m-2 rounded-xl  ">
                  Solar Flare Batteries
                </div>
              </a> */}
              {/* <a
                href="/assets/images/ATS_Brochure_INVERTER_2.0.pdf"
                className="w-full"
              >
                <div className=" textclass font-semibold px-5 py-2  hover:text-white hover:bg-primary duration duration-700 text-start  m-2 rounded-xl  ">
                  Inverter Batteries
                </div>
              </a> */}
              {/* <a href="/assets/images/E-Rickshaw_pdf.pdf" className="w-full">
                <div className=" textclass font-semibold px-5 py-2  hover:text-white hover:bg-primary duration duration-700 text-start  m-2 rounded-xl  ">
                  E-Rickshaw Batteries
                </div>
              </a> */}
              {/* <a
                href="/assets/images/ATS_Catalog_SpecSheet.compressed.pdf"
                className="w-full"
              >
                <div className=" textclass font-semibold px-5 py-2  hover:text-white hover:bg-primary duration duration-700 text-start  m-2 rounded-xl  ">
                  Powerful Tubular Batteries
                </div>
              </a> */}
            {/* </div>
          </li> */}
        </ul>
        <div className=" lg:block hidden group">
          <Link
            to="/contact"
            onClick={closeMenu}
            className="link-color  text-white group-hover:text-white "
          >
            <div className=" px-8 py-2 text-sm uppercase  border-2 border-[#EC2028]  font-semibold  rounded-md  group-hover:bg-primary">
              Contact Us
            </div>{" "}
          </Link>
        </div>

        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes size={30} style={{ color: "#ffffff" }} />
          ) : (
            <FaBars size={30} style={{ color: "#ffffff" }} />
          )}
        </div>
      </nav>
      {bgcolor ? (
        <hr
          className={
            color ? "bg-black text-black" : "bg-white  w-[93%] mx-auto"
          }
        />
      ) : null}
    </div>
  );
};

export default Navbar;
