import { useInView } from "framer-motion";
import React, { useRef } from "react";
import { BsArrowRight, BsArrowRightCircle } from "react-icons/bs";
import { ImInsertTemplate } from "react-icons/im";
import { Link, NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const WhatWeDo = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const data = [
    {
      id: 1,
      image: "/assets/images/we1.png",
      title: "Eco-Friendly",
    },
    {
      id: 2,
      image: "/assets/images/we2.png",
      title: "Fair Price  ",
    },
    {
      id: 3,
      image: "/assets/images/we3.png",
      title: "Less Evaporation  ",
    },
    {
      id: 4,
      image: "/assets/images/we4.png",
      title: "High Efficiency      ",
    },
    {
      id: 5,
      image: "/assets/images/leak.png",
      title: "Leak Proof      ",
    },
    {
      id: 6,
      image: "/assets/images/we6.png",
      title: "Low internal Resistance",
    },
    {
      id: 7,
      image: "/assets/images/we7.png",
      title: "Fast Recharge      ",
    },
    {
      id: 8,
      image: "/assets/images/we8.png",
      title: "International Delivery  ",
    },
    {
      id: 9,
      image: "/assets/images/we9.png",
      title: "Longer Backup  ",
    },
    {
      id: 10,
      image: "/assets/images/we10.png",
      title: "Leak Proof WLI      ",
    },
    {
      id: 11,
      image: "/assets/images/we11.png",
      title: "High Quality  ",
    },
    {
      id: 12,
      icon: <BsArrowRightCircle className="w-10 h-10 iconTWL" />,
      css: "bg-primary text-white",
      to: "/about"

    },
  ];
  return (
    <div className="bg-secondary py-16">
      <motion.div className="container-ats md:px-0 md:py-0 py-8 px-5 lg:grid lg:grid-cols-12 lg:items-center justify-between bg-white rounded-md drop-shadow-2xl shadow-xl"
        ref={ref}
        initial={{ y: isInView ? 70 : 0, opacity: isInView ? 0 : 0 }}
        animate={{ y: isInView ? 0 : 70, opacity: isInView ? 1 : 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="col-span-3 p-4 md:block hidden">
          <img
            src="/assets/images/Group 413.png"
            className="lg:h-[395px] h-full drop-shadow-2xl w-full"
          />
        </div>
        <div className="col-span-5 ">
          <div className="space-y-4 lg:px-12 px-2">
            <h2 className="text-2xl font-bold tracking-widest">
              <span className="decoration-primary">WHAT</span> WE DO ?
              <h2
                className="w-24 h-[2px] bg-primary mt-2"
                ref={ref}
                style={{
                  transform: isInView ? "none" : "translateX(200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
              ></h2>
            </h2>

            <p className="font-semibold tracking-wider">
              We build batteries to deliver endless power with minimal work for
              you to do.
            </p>
            <p className="tracking-wide text-justify">
              The hunger for electricity is endless and equally important for
              all sizes of factories, offices, households, etc. ATS Batteries are building the solution based on the latest technology
              with the least maintenance requirement and long durability.
            </p>
            <p>
              <NavLink to="/about">
                <button className="flex lg:my-0 my-4 gap-3 items-center justify-items-center group bg-primary rounded-full px-6 py-2 cursor-pointer mt-3">
                  <BsArrowRight
                    size={24}
                    className="text-white group-hover:translate-x-2 duration-150 "
                  />
                  <p className="text-white ">Know More</p>
                </button>
              </NavLink>
            </p>
          </div>
        </div>
        <div className="col-span-4">
          <div className="grid grid-rows-4  grid-flow-col">
            {data.map((obj, e) => (
              <div
                key={e}
                className={`${obj.css} border flex flex-col justify-center items-center py-2.5 -space-y-4`}
              >

                <img src={obj.image} className={`p-4 w-16`} />
                {obj.icon ? <Link to="/about">
                  {obj.icon}
                </Link> : null}

                <p className="text-sm">{obj.title}</p>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default WhatWeDo;
