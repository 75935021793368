import React, { useRef } from "react";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { RiArrowRightSFill, RiArrowLeftSFill } from "react-icons/ri";
import { BsArrowRight } from "react-icons/bs";
import Banner from "./Banner";
import { Link } from "react-router-dom";
import { motion, useInView } from "framer-motion";

const BannerSliderSlick = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const data = [
    {
      id: 1,
      img: "assets/icons/icon1.png",
      title: "Worldwide Support",
      para: "ATS Batteries is delivering across many countries and is expanding its global presence. ",
    },
    {
      id: 2,
      img: "assets/icons/icon2.png",
      title: "Manufacturing",
      para: "Our manufacturing team is meticulous while assembling the batteries for excellence.      ",
    },
    {
      id: 3,
      img: "assets/icons/icon3.png",
      title: "Quality Assurance",
      para: "Each battery undergoes several testing stages to ensure the best performance and standard.      ",
    },
  ];
  const Banner = [
    {
      id: 1,
      img: "/assets/images/bannerimg.png",
      smalltitle: "Enjoy Longer Power Backups ",
      title: "Have an uninterrupted supply like it never stopped ",
      heading:
        "With rigid power-packed support and assurance, ATS Batteries is the best you need",
    },
    {
      id: 2,
      img: "/assets/images/bannerTwo.png",
      smalltitle: "Have A Quick Recharge!",
      title: "We improvise the experience With perfection",
      heading:
        "Fast recharging and long power reserve assure all-day power running.",
    },
    {
      id: 3,
      img: "/assets/images/bannerThree.png",
      smalltitle: "Low Maintenance, Long Running",
      title: "Our batteries are designed To run in the long races",
      heading:
        "Give your electricals a boost of energy with the ultra-low maintenance requirement ATS BATTERIES.",
    },
  ];



  const slider = useRef();

  const next = () => {
    slider.current.slickNext();
  };
  const pre = () => {
    slider.current.slickPrev();
  };

  const settings = {
    dots: false,
    fade: true,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    prevArrow: <FiArrowLeft size={50} />,
    nextArrow: <FiArrowRight size={50} />,
  };
  return (
    <>
      <div className="relative md:h-auto h-screen bg-secondary">
        <Slider ref={(c) => (slider.current = c)} {...settings}>
          {Banner.map((e) => (
            <div id={e.id} className="relative">
              <img
                src={e.img}
                className=" w-full md:h-auto h-screen object-cover"
              />

              <div className="absolute md:px-20 px-6 flex text-white justify-between z-40 top-0 items-center h-screen  w-full">
                <div className="md:w-[60%]  pt-28">
                  <div className="flex items-center gap-5">
                    <div className="h-10 w-[0.15rem] bg-primary"></div>
                    <p className="text-sm text-primary tracking-widest">
                      {e.smalltitle}
                    </p>
                  </div>
                  <h1 className="space-y-2 fontfamily textshadow text-2xl py-3 w-[94%] lg:text-5xl text-white  font-semibold textshadow capitalize">
                    <p className=" leading-tight"> {e.title}</p>
                  </h1>
                  <p className="lg:text-xl text-lg text-white"> {e.heading}</p>
                  <Link to="/about">
                    <button className="flex gap-3 items-center justify-items-center group bg-primary rounded-full px-6 py-2 cursor-pointer mt-3">

                      <BsArrowRight
                        size={24}
                        className="text-white group-hover:translate-x-2 duration-150"
                      />
                      <p className="text-white ">Learn More</p>

                    </button>
                  </Link>
                </div>
                <div className=" text-white w-24 h-24 bg-red-600 md:flex hidden justify-center items-center">
                  <span className="text-4xl">0</span>
                  <p className="text-4xl">{e.id}</p>/3
                </div>
              </div>
            </div>
          ))}
        </Slider>

        <div className=" absolute space-y-6 bottom-10 left-16">
          <div className="lg:block hidden pr-16">
            <div className="w-100 bg-[#000000c5] rounded-xl py-5  mx-auto ">
              <div className="px-20 ">
                <div className="md:grid grid-cols-3 py-5  gap-7">
                  {data.map((item) => (
                    <>
                      <motion.div className=" flex items-center gap-5"
                        ref={ref}
                        initial={{ y: isInView ? 70 : 0, opacity: isInView ? 0 : 0 }}
                        animate={{ y: isInView ? 0 : 70, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: 1 }}
                      >
                        <div>
                          <img src={item.img} alt="icon" className="w-20 " />
                        </div>
                        <div>
                          <h1 className="text-white text-lg font-semibold">
                            {" "}
                            {item.title}
                          </h1>
                          <hr className="bg-white my-2" />
                          <p className="text-white text-xs">{item.para}</p>
                          <BsArrowRight
                            size={24}
                            className="text-white cursor-pointer hover:translate-x-2 duration-150 mt-2"
                          />
                        </div>
                      </motion.div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="absolute text-red-500 md:bottom-2 xl:-top-10  2xl:-top-24 right-7 md:flex hidden items-center h-full gap-8 pr-12 flex-col justify-center"
          style={{ textAlign: "center" }}
        >
          <div className="space-x-2">
            <button className="button text-white prevArrow" onClick={pre}>
              <RiArrowLeftSFill size={50} />
            </button>
            <button className="button text-white nextArrow" onClick={next}>
              <RiArrowRightSFill size={50} />
            </button>
          </div>
        </div>
      </div>
      <div className="lg:hidden block bg-secondary pt-8">
        <div className="px-5">
          <div className="w-100 bg-[#000000c5] rounded-xl py-5  mx-auto ">
            <div className="px-6">
              <div className=" py-5">
                {data.map((item) => (
                  <>
                    <div className=" flex items-center gap-5">
                      <div>
                        <img
                          src={item.img}
                          alt="icon"
                          className="w-16 md:w-full"
                        />
                      </div>
                      <div>
                        <h1 className="text-white text-lg font-semibold">
                          {" "}
                          {item.title}
                        </h1>
                        <hr className="bg-white my-2" />
                        <p className="text-white text-xs">{item.para}</p>
                        <BsArrowRight
                          size={24}
                          className="text-white cursor-pointer hover:translate-x-2 duration-150 mt-2"
                        />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerSliderSlick;
