import React from "react";
import Heading from "../common/Heading";

const Model = () => {
  const data = [
    {
      id: 1,
      model: "ATS-200      ",
      capacity: "200 Ah",
      gross: "70-71KGS",
      l: "505",
      w: "190",
      h: "410",
    },
    {
      id: 2,
      model: "ATS-240",
      capacity: "240 Ah",
      gross: "75-76 KGS",
      l: "505",
      w: "190",
      h: "410",
    },
  ];
  return (
    // space-y-8 py-8

    <div className="space-y-8 py-8">
      <div className="container-ats">
        <Heading
          title="OUR MODELS @C10"
          description="We are dealing with several ATS Batteries Flare Batteries models to provide you with upgraded versions with the best power supply."
        />
      </div>

      {/* container-ats */}
      {/* grid grid-cols-1 lg:grid-cols-5 */}

      <div className="container-ats overflow-auto">
        <table className="table-auto w-full ">
          <thead>
            <tr>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                S.No.
              </th>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Model
              </th>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Capacity Ah @ C10
              </th>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Gross Weight ( 3+/- )
              </th>

              <th
                colSpan="3"
                className="border-b-2 border-white bg-black text-white p-4 rounded"
              >
                Dimension
              </th>
            </tr>
            <tr>
              <th className=" border-r-2 border-white bg-black text-white p-4 rounded">
                L
              </th>
              <th className="border-r-2 border-white bg-black text-white p-4 rounded">
                W
              </th>
              <th className="border-r-2 border-white bg-black text-white p-4 rounded">
                H
              </th>
            </tr>
          </thead>
          <tbody className="">
            {data.map((e, obj) => (
              <tr className="">
                <td className="p-2 border-r-2 border-white bg-secondary lg:text-center">
                  {e.id}
                </td>
                <td className="lg:p-2 border-r-2 border-white bg-secondary lg:text-center">
                  {e.model}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary lg:text-center">
                  {e.capacity}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary lg:text-center">
                  {e.gross}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary lg:text-center">
                  {e.l}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary lg:text-center">
                  {e.w}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary lg:text-center">
                  {e.h}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Model;
