import React from "react";
import Heading from "../common/Heading";

const AutomativeModel = () => {
  const data = [
    {
      id: 1,
      model: "ATSP-38B20 ",
      capacity: "35 Ah",
      // warranty: "18+18",
      l: "200",
      w: "125",
      h: "225",
    },
    {
      id: 2,
      model: "ATSP-65D26",
      capacity: "65 Ah",
      // warranty: "18+18 ",
      l: "260",
      w: "175",
      h: "210",
    },
    {
      id: 3,
      model: "ATSP-900MINI",
      capacity: "90 Ah",
      // warranty: "18+18",
      l: "310",
      w: "175",
      h: "210",
    },
    {
      id: 4,
      model: "ATSP-950",
      capacity: "90 Ah",
      // warranty: "18+18",
      l: "410",
      w: "175",
      h: "210",
    },
    {
      id: 5,
      model: "ATSP-1100 ",
      capacity: "100 Ah ",
      // warranty: "18+18",
      l: "410",
      w: "175",
      h: "210",
    },
    {
      id: 6,
      model: "ATSP-1350JCB",
      capacity: "120 Ah",
      // warranty: "18+18",
      l: "505",
      w: "182",
      h: "257",
    },
    {
      id: 7,
      model: "ATSP-1500",
      capacity: "150 Ah",
      // warranty: "18+18",
      l: "505",
      w: "220",
      h: "225",
    },
  ];
  return (
    // space-y-8 py-8

    <div className="space-y-8 py-8">
      <div className=" container-ats">
        <Heading
          title="OUR MODELS @C20"
          description="Our automotive batteries are compatible with all cars and trucks, and they suit the best with their long life and lesser maintenance requirements.
          "
        />
      </div>

      <div className="container-ats overflow-auto">
        <table className="table-auto w-full ">
          <thead>
            <tr>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                S.No.
              </th>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Model
              </th>
              <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Capacity Ah @ C20
              </th>
              {/* <th
                rowSpan="2"
                className="border-r-2 border-white bg-black text-white p-4 rounded"
              >
                Warranty
              </th> */}

              <th
                colSpan="3"
                className="border-b-2 border-white bg-black text-white p-4 rounded"
              >
                Dimension(mm)
              </th>
            </tr>
            <tr>
              <th className=" border-r-2 border-white bg-black text-white p-4 rounded">
                L
              </th>
              <th className="border-r-2 border-white bg-black text-white p-4 rounded">
                W
              </th>
              <th className="border-r-2 border-white bg-black text-white p-4 rounded">
                H
              </th>
            </tr>
          </thead>
          <tbody className="">
            {data.map((e, obj) => (
              <tr className="">
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.id}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.model}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.capacity}
                </td>
                {/* <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.warranty}
                </td> */}
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.l}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.w}
                </td>
                <td className="p-2 border-r-2 border-white bg-secondary text-center">
                  {e.h}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AutomativeModel;
