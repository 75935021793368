import React from "react";
import Heading from "../common/Heading";

import { AiFillCar } from "react-icons/ai";
import { GiMineTruck } from "react-icons/gi";

const AutomativeApplication = () => {
  const data = [
    {
      id: 1,
      img: (
        <AiFillCar className="w-12 h-12 text-primary group-hover:text-white" />
      ),
      title: "Cars  ",
      des: "Run your cars like butter on the roads as we might not assure you of your fuel, but our batteries are the last search you will do to find the best Automotive Battery. Find your car a compatible power partner with guaranteed long life.  The primary need of the automobile, after anything, is the battery, as it directly impacts your car's life. ",
    },
    {
      id: 2,
      img: (
        <GiMineTruck className="w-12 h-12 text-primary group-hover:text-white" />
      ),
      title: "Truck  ",
      des: "Take your heavy loads from one place to another without getting shocks from your truck battery. ATS Batteries has leading automotive batteries to accompany the four wheels on the roads smoothly and seamlessly. With fast charging and longer life, ATS Batteries Automotive Batteries are your best ride partner.",
    },
  ];
  return (
    <div className="px-4 bg-secondary lg:space-y-16 space-y-8 py-12">
      <div className="container-ats">
        <Heading
          title="OUR APPLICATION"
          description="Our Automotive Batteries are compatible with all automobiles. With ours, you can put a little extra into yours."
        />
      </div>
      <div className="grid lg:grid-cols-3 container-ats  grid-cols-1  lg:px-10 px-0 mx-auto  gap-4 items-center justify-items-center">
        {data.map((e, obj) => (
          <>
            {" "}
            <div className="group rounded  bg-white hover:bg-black hover:-translate-y-4 duration-500 p-8 shadow-md hover:shadow-xl">
              {e.img}
              <h2 className="group-hover:text-white font-semibold py-2">
                {e.title}
              </h2>
              <p className="group-hover:text-white text-sm tracking-wide leading-loose">
                {e.des}
              </p>
            </div>

          </>
        ))}
        <div>
          <img src="assets/img/FRONT.png" className="w-full h-52" />
        </div>
      </div>
    </div>
  );
};

export default AutomativeApplication;
