import React from "react";
import ContactFooter from "../components/common/ContactFooter";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import Banner from "../components/home/Banner";
import ApplicationInverter from "../components/InverterBatteries/ApplicationInverter";
import DimensionsInverter from "../components/InverterBatteries/DimensionsInverter";
import Infoinverter from "../components/InverterBatteries/Infoinverter";
import ModelInverter from "../components/InverterBatteries/ModelInverter";

const InverterBatteries = () => {
  return (
    <div>
      <Navbar />
      <Banner
        img="/assets/battery/invertor.jpg"
        pageTitle="Long Lasting Power Runs"
        title="Get the boost of power 
        At minimal maintenance
        "
      />
      <Infoinverter />
      <ApplicationInverter />
      <ModelInverter />
      {/* <DimensionsInverter/> */}
      <ContactFooter />
      <Footer />
    </div>
  );
};

export default InverterBatteries;
