import { useInView } from "framer-motion";
import { Link, NavLink } from "react-router-dom";
import React, { useRef } from "react";
import { BsArrowRight } from "react-icons/bs";

const OurBlogs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <div className="bg-secondary">
      <div className="container-ats">
        <div className=" py-16 lg:grid lg:grid-cols-12 gap-8  lg:px-0 px-5">
          <div className="bg-white shadow-md border lg:block hidden  col-span-5 border-gray-200 rounded overflow-hidden group">
            <img
              className="rounded-t-lg group-hover:scale-105 duration-300"
              src="assets/battery/bl-1.png"
              alt=""
            />

            <div className="p-5 space-y-4">
              <h5 className="text-gray-900 font-bold text-xl tracking-tight mb-2">
                The Evolution of Tubular Batteries and Their Impact on Modern
                Energy Solutions
              </h5>

              <p className="h-[2px] bg-primary w-100"></p>
              <p className="font-normal text-gray-700 mb-3 text-sm leading-6">
                The quest for reliable energy storage solutions has been a driving force in technological
                advancement for decades. Tubular batteries have emerged as a critical component in this
                arena, offering unmatched reliability and efficiency. This blog explores the evolution of
                tubular batteries, their technological advancements, and their impact on modern energy
                solutions.
              </p>
              <div className="italic tracking-widest  font-medium rounded-lg text-sm flex justify-between md:px-6">
                <p>( 06/07/2022 )</p>
                <Link
                  to="/blog-detail4"
                  className="italic  text-primary font-medium rounded-lg text-sm"
                >
                  Read More
                </Link>
              </div>
              {/* <div>
                <Link
                  to="/blog-detail1"
                  className="italic  text-primary float-right font-medium rounded-lg text-sm px-3 py-2  inline-flex items-center"
                >
                  Read More
                </Link>
              </div> */}
            </div>
          </div>
          <div className="col-span-7 space-y-4">
            <h2 className="text-4xl font-semibold uppercase   tracking-widest ">
              <span className="">Our</span> Blog
              <h2
                className="w-24 h-[2px] bg-primary mt-2"
                ref={ref}
                style={{
                  transform: isInView ? "none" : "translateX(200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
              ></h2>
            </h2>

            <p className="text-sm font-semibold lg:flex  tracking-wider">
              Know more about the industry and the market of batteries. Read,
              Learn, and get lit up with knowledge and ATS Batteries.
            </p>
            <div className="bg-white shadow-md border  lg:hidden block   col-span-5 border-gray-200 rounded overflow-hidden group">
              <img
                className="rounded-t-lg group-hover:scale-105 duration-300 w-full h-full"
                src="assets/battery/bl-1.png"
                alt=""
              />

              <div className="p-5 space-y-4">
                <h5 className="text-gray-900 font-bold text-xl tracking-tight mb-2">
                  Tips and Tricks to find the best inverter battery for your
                  house
                </h5>

                <p className="h-[2px] bg-primary w-100"></p>
                <p className="font-normal text-gray-700 mb-3 text-sm leading-6">
                  If you are planning to buy an inverter battery for your
                  household, then there are several things you should consider.
                  It is very important to understand what the right inverter is
                  for your home. The power requirements at your home can be
                  different from those at your neighbor’s home.
                </p>
                <div className="italic tracking-widest  font-medium rounded-lg text-sm flex justify-between md:px-6">
                  <p>( 06/07/2022 )</p>
                  <Link
                    to="/blog-detail4"
                    className="italic  text-primary font-medium rounded-lg text-sm"
                  >
                    Read More
                  </Link>
                </div>
                {/* <div className="italic tracking-widest   font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center">
                  ( 06/07/2022 )
                </div>
                <div>
                  <Link
                    to="/blog-detail1"
                    className="italic  text-primary float-right  font-medium rounded-lg text-sm px-3 py-2  inline-flex items-center"
                  >
                    Read More
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="lg:flex gap-4  ">
              <div className="bg-white shadow-md border border-gray-200 rounded group overflow-hidden">
                <img
                  className="rounded-t-lg w-full group-hover:scale-105 duration-300"
                  src="assets/battery/b-img5.png"
                  alt=""
                />

                <div className="p-4">
                  <h5 className="text-gray-900 font-bold text-xl tracking-tight mb-2">
                  Installing and Maintaining Tubular Batteries: Best Practices for
                  Longevity and Efficiency
                  </h5>

                  <div className="italic tracking-widest  font-medium rounded-lg text-sm flex justify-between md:px-6">
                    <p>( 08/08/2022 )</p>
                    <Link
                      to="/blog-detail5"
                      className="italic  text-primary font-medium rounded-lg text-sm"
                    >
                      Read More
                    </Link>
                  </div>
                  {/* <div className="italic tracking-widest  font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center">
                    ( 08/08/2022 )
                  </div>
                  <div>
                    <Link
                      to="/blog-detail2"
                      className="italic  text-primary float-right  font-medium rounded-lg text-sm px-3 py-2  inline-flex items-center"
                    >
                      Read More
                    </Link>
                  </div> */}
                </div>
              </div>
              <div className="bg-white shadow-md border lg:mt-0 mt-5 border-gray-200 rounded group overflow-hidden">
                <img
                  className="rounded-t-lg w-full group-hover:scale-105 duration-300"
                  src="assets/battery/b-img6.png"
                  alt=""
                />

                <div className="p-4">
                  <h5 className="text-gray-900 font-bold text-xl tracking-tight mb-2">
                   How Tubular Batteries Revolutionize Backup Power Systems
                  </h5>

                  <div className="italic tracking-widest  font-medium rounded-lg text-sm flex justify-between md:px-6">
                    <p>( 15/05/2022 )</p>
                    <Link
                      to="/blog-detail6"
                      className="italic  text-primary font-medium rounded-lg text-sm"
                    >
                      Read More
                    </Link>
                  </div>
                  {/* <div className="italic tracking-widest focus:ring-4  font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center">
                    ( 15/05/2022 )
                  </div>
                  <div>
                    <Link
                      to="/blog-detail3"
                      className="italic focus:ring-4 text-primary float-right  font-medium rounded-lg text-sm px-3 py-2  inline-flex items-center"
                    >
                      Read More
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
            <p>
              <NavLink to="/blog">
                <button className="flex gap-3 items-center  lg:mx-0 md:mx-5 px-10  justify-items-center group bg-primary rounded-full lg:px-6 py-2 cursor-pointer">
                  <BsArrowRight
                    size={24}
                    className="text-white group-hover:translate-x-2 duration-150"
                  />
                  <p className="text-white">Know More</p>
                </button>
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurBlogs;
